import React from 'react';

class InformationWindow extends React.Component {
    wasVisible = false;

    constructor() {
        super();

        this.wrapperRef = React.createRef();
        this.contentWrapperRef = React.createRef();
        this.contentRef = React.createRef();
    }

    componentDidUpdate() {
        this.adjustWindowPosition();
    }

    adjustWindowPosition() {
        const windowElement = this.wrapperRef.current;
        const contentWrapperElement = this.contentWrapperRef.current;
        const contentElement = this.contentRef.current;

        let { x, y } = this.props;
        x += 24;
        y += 24;

        if (this.props.visible) {
            if (windowElement && contentWrapperElement && contentElement) {
                const windowHeight = contentElement.offsetHeight;
                const windowBottom = y + windowHeight;
                const screenHeight = window.innerHeight;

                if (windowBottom > screenHeight - 1) {
                    windowElement.style.top = `${screenHeight - 1 - windowHeight}px`;
                } else {
                    windowElement.style.top = `${y}px`;
                }

                let windowWidth = contentElement.offsetWidth;
                const windowRight = x + windowWidth;
                const screenWidth = window.innerWidth;

                if (windowRight > screenWidth - 1) {
                    windowElement.style.left = null;
                    windowElement.style.right = `${1}px`;
                } else {
                    windowElement.style.left = `${x}px`;
                    windowElement.style.right = null;
                }

                if (!this.wasVisible) {
                    const originalTransition = contentWrapperElement.style.transition;
                    contentWrapperElement.style.transition = 'none';
                    contentWrapperElement.style.width = 0;
                    contentWrapperElement.style.height = 0;
                    contentWrapperElement.style.opacity = 0;

                    requestAnimationFrame(() => {
                        contentWrapperElement.style.transition = originalTransition;
                        contentWrapperElement.style.width = `${windowWidth}px`;
                        contentWrapperElement.style.height = `${windowHeight}px`;
                        contentWrapperElement.style.opacity = 1;
                    });
                }
            }
        } else if (this.wasVisible) {
            if (contentWrapperElement) {
                const windowWidth = contentElement.offsetWidth;
                const windowHeight = contentElement.offsetHeight;
                contentWrapperElement.style.width = 0;
                contentWrapperElement.style.height = 0;
                contentWrapperElement.style.opacity = 0;
                setTimeout(() => {
                    contentWrapperElement.style.width = `${windowWidth}px`;
                    contentWrapperElement.style.height = `${windowHeight}px`;
                    contentWrapperElement.style.opacity = 1;
                }, 200);
            }
        }

        this.wasVisible = this.props.visible;
    }

    getClasses() {
        let classes = ['gantt-info-display-wrapper'];
        if (this.props.visible) classes.push('visible');
        return classes.join(' ');
    }

    render() {
        return (
            <div
                ref={this.wrapperRef}
                className={this.getClasses()}
            >
                <div
                    ref={this.contentWrapperRef}
                    className={'gantt-info-content-wrapper'}
                >
                    <div
                        ref={this.contentRef}
                        className={'gantt-info-display'}
                    >
                        {this.props.content}
                    </div>
                </div>
            </div>
        );
    }
}

export default InformationWindow;
