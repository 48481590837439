import moment from 'moment';

var CURR_BAR_ID = 0;
var CURR_ROW_ID = 0;
var CURR_EXCEPTION_ID = 0;
var CURR_CONNECTION_ID = 0;
var CURR_DATE_LINE_ID = 0;

class Data {
    constructor() {
        this.rows = [];
        this.globalCalendar = new GanttCalendar();
        this.history = [];
        this.historyIndex = -1;
        this.maxHistorySize = 5;
        this.dateLines = [];
        this.hideInvisibleBars = false;
    }

    addRow() {
        let row = new Row();
        row.id = ++CURR_ROW_ID;
        this.rows.push(row);
        return row;
    }

    getVisibleRows(startDate, endDate) {
        let start = startDate;
        let end = endDate;

        if (this.hideInvisibleBars) {
            let inc = localStorage.getItem('sf_anim_start');
            if (inc) inc = parseInt(inc);
            else inc = 7;
            start = moment().subtract(inc, 'days');

            inc = localStorage.getItem('sf_anim_end');
            if (inc) inc = parseInt(inc);
            else inc = 7;
            end = moment().add(inc, 'days');
        }

        let hiddenRows = [];
        for (let i = 0; i < this.rows.length; i++) {
            if (this.rows[i].extended === false) {
                hiddenRows = hiddenRows.concat(this.recursiveDissolve(this.rows[i]));
            }
        }

        let visibleRows = [];
        for (let i = 0; i < this.rows.length; i++) {
            if (!hiddenRows.includes(this.rows[i]) && this.rowVisible(this.rows[i], start, end)) visibleRows.push(this.rows[i]);
        }

        return visibleRows;
    }

    recursiveDissolve(row) {
        let result = [];
        for (let i = 0; i < row.childRows.length; i++) {
            let childRow = row.childRows[i];
            result.push(childRow);

            if (childRow.childRows.length > 0) result = result.concat(this.recursiveDissolve(childRow));
        }
        return result;
    }

    addBar(bar) {
        let row = new Row();
        bar.id = ++CURR_BAR_ID;
        bar.row = row;
        bar.type = 'task';
        row.addBar(bar);
        this.rows.push(row);
        return bar;
    }

    removeBar(bar) {
        for (let i = 0; i < this.rows.length; i++) {
            const index = this.rows[i].bars.indexOf(bar);
            if (index > -1) {
                this.rows[i].bars.splice(index, 1);
            }
        }
    }

    applyChanges(bar) {
        let b = this.getBar(bar.id);
        if (b !== undefined) {
            b.start = bar.start;
            b.end = bar.end;
        }
    }

    allBars() {
        let result = [];
        for (let i = 0; i < this.rows.length; i++) {
            result = result.concat(this.rows[i].bars);
        }
        return result;
    }

    simpleCopyRows() {
        let result = [];
        for (let i = 0; i < this.rows.length; i++) {
            let row = new Row();
            for (let j = 0; j < this.rows[i].bars.length; j++) {
                row.bars.push(this.rows[i].bars[j].simpleCopy());
            }
            row.id = this.rows[i].id;
            row.extended = this.rows[i].extended;
            row.childRows = this.rows[i].childRows;
            row.parent = this.rows[i].parent;
            row.summaryBar = this.rows[i].summaryBar;
            row.calendar = this.rows[i].calendar;
            row.useCalendarForChildren = this.rows[i].useCalendarForChildren;
            result.push(row);
        }

        for (let i = 0; i < result.length; i++) {
            if (result[i].childRows.length > 0) {
                let overhauledChildRows = [];
                for (let j = 0; j < result[i].childRows.length; j++) {
                    let childRowIndex = this.rows.indexOf(result[i].childRows[j]);
                    let childRow = result[childRowIndex];
                    overhauledChildRows.push(childRow);
                }
                result[i].childRows = overhauledChildRows;

                let overhauledParent = undefined;
                if (result[i].parent !== undefined) {
                    overhauledParent = result[this.rows.indexOf(result.parent)];
                }
                result[i].parent = overhauledParent;
            }
        }

        result.forEach((row) => {
            for (let i = 0; i < row.bars.length; i++) {
                let bar = row.bars[i];
                bar.originalStart = moment(bar.start);
                bar.originalEnd = moment(bar.end);
                bar.row = row;
                let overhauledConnections = [];
                let overhauledLinkConnections = [];
                for (let i = 0; i < bar.connections.length; i++) {
                    let temporaryFromBar = this.findBar(result, bar.connections[i].fromBar.id);
                    let temporaryToBar = this.findBar(result, bar.connections[i].toBar.id);
                    let connection = new Connection(temporaryFromBar, temporaryToBar);
                    connection.id = bar.connections[i].id;
                    connection.puffer = bar.connections[i].puffer;
                    overhauledConnections.push(connection);
                }
                for (let i = 0; i < bar.linkConnections.length; i++) {
                    let temporaryFromBar = this.findBar(result, bar.linkConnections[i].fromBar.id);
                    let temporaryToBar = this.findBar(result, bar.linkConnections[i].toBar.id);
                    let connection = new Connection(temporaryFromBar, temporaryToBar);
                    connection.id = bar.linkConnections[i].id;
                    connection.puffer = bar.linkConnections[i].puffer;
                    overhauledLinkConnections.push(connection);
                }
                bar.connections = overhauledConnections;
                bar.linkConnections = overhauledLinkConnections;
            }
            if (row.summaryBar !== undefined) {
                row.summaryBar = row.bars.find((bar) => bar.id === row.summaryBar.id);
            }
        });

        return result;
    }

    pushHistory() {
        for (let i = this.history.length - 1; i >= this.historyIndex + 1; i--) {
            this.history.splice(i, 1);
        }

        if (this.history.length > this.maxHistorySize) {
            const diff = this.history.length - this.maxHistorySize;
            for (let i = diff - 1; i >= 0; i--) {
                this.history.splice(i, 1);
            }
        }

        let state = this.simpleCopyRows();
        this.history.push(state);
        this.historyIndex = this.history.length - 1;
    }

    revertHistory() {
        this.loadHistory(this.historyIndex - 1);
    }

    restoreHistory() {
        this.loadHistory(this.historyIndex + 1);
    }

    loadHistory(index) {
        if (index < 0 || index > this.history.length - 1) return;
        let state = this.history[index];

        if (state !== undefined) {
            for (let i = 0; i < this.rows.length; i++) {
                for (let j = 0; j < state.length; j++) {
                    if (this.rows[i].id === state[j].id) {
                        for (let k = 0; k < this.rows[i].bars.length; k++) {
                            for (let l = 0; l < state[j].bars.length; l++) {
                                if (this.rows[i].bars[k].id === state[j].bars[l].id) {
                                    this.rows[i].bars[k].start = moment(state[j].bars[l].start);
                                    this.rows[i].bars[k].end = moment(state[j].bars[l].end);
                                }
                            }
                        }
                        break;
                    }
                }
            }
        }

        this.historyIndex = index;
    }

    findBar(rows, barId) {
        for (let i = 0; i < rows.length; i++) {
            let row = rows[i];
            for (let j = 0; j < row.bars.length; j++) {
                if (row.bars[j].id === barId) {
                    return row.bars[j];
                }
            }
        }
    }

    simpleCopyBars() {
        let result = [];
        for (let i = 0; i < this.rows.length; i++) {
            for (let j = 0; j < this.rows[i].bars.length; j++) {
                result.push(this.rows[i].bars[j].simpleCopy());
            }
        }

        result.forEach((bar) => {
            bar.originalStart = moment(bar.start);
            bar.originalEnd = moment(bar.end);

            let overhauledConnections = [];
            let overhauledLinkConnections = [];
            for (let i = 0; i < bar.connections.length; i++) {
                let temporaryFromBar = result.find((b) => b.id === bar.connections[i].fromBar.id);
                let temporaryToBar = result.find((b) => b.id === bar.connections[i].toBar.id);
                let connection = new Connection(temporaryFromBar, temporaryToBar);
                connection.id = bar.connections[i].id;
                connection.puffer = bar.connections[i].puffer;
                overhauledConnections.push(connection);
            }
            for (let i = 0; i < bar.linkConnections.length; i++) {
                let temporaryFromBar = result.find((b) => b.id === bar.linkConnections[i].fromBar.id);
                let temporaryToBar = result.find((b) => b.id === bar.linkConnections[i].toBar.id);
                let connection = new Connection(temporaryFromBar, temporaryToBar);
                connection.id = bar.connections[i].id;
                connection.puffer = bar.connections[i].puffer;
                overhauledLinkConnections.push(connection);
            }
            bar.connections = overhauledConnections;
            bar.linkConnections = overhauledLinkConnections;
        });

        return result;
    }

    getBar(id) {
        return this.allBars().find((bar) => bar.id === id);
    }

    getRow(id) {
        return this.rows.find((row) => row.id === id);
    }

    moveBar(id, newStart) {
        let bar = this.getBar(id);
        if (bar !== undefined) bar.moveStart(newStart);
    }

    addDateLine(date) {
        let dateLine = new DateLine(date);
        dateLine.id = ++CURR_DATE_LINE_ID;
        this.dateLines.push(dateLine);
        return dateLine;
    }

    addLiveDateLine(minuteOffset) {
        let dateLine = new DateLine();
        dateLine.live = true;
        if (!minuteOffset) minuteOffset = 0;
        dateLine.minuteOffset = minuteOffset;
        dateLine.id = ++CURR_DATE_LINE_ID;
        this.dateLines.push(dateLine);
        return dateLine;
    }

    removeDateLine(dateLine) {
        for (let i = this.dateLines.length - 1; i >= 0; i--) {
            if (this.dateLines[i].id === dateLine.id) {
                this.dateLines[i].splice(i, 1);
            }
        }
    }

    rowVisible(row, startDate, endDate) {
        if (startDate === undefined || endDate === undefined) return true;
        if (!this.hideInvisibleBars) return true;

        const bars = row.bars;

        let onlySummary = true;
        for (let i = 0; i < bars.length; i++) {
            const bar = bars[i];
            if (bar.type !== 'summary') {
                onlySummary = false;
                if (bar.end.isSameOrAfter(startDate, 'minutes') && bar.start.isBefore(endDate, 'minutes')) {
                    return true;
                }
            }
        }
        if (onlySummary && bars.length > 0) {
            for (let i = 0; i < row.childRows.length; i++) {
                let childRow = row.childRows[i];
                if (this.rowVisible(childRow, startDate, endDate)) {
                    return true;
                }
            }
        }
        return false;
    }

    static resolveConnections(bars, ignore, trackedBarId, selectedBars) {
        if (ignore === undefined) {
            ignore = [];
        }

        let toResolve = [];
        bars.forEach((bar) => {
            ignore.push(bar.id);
            bar.connections.forEach((connection) => {
                const connectedBar = connection.toBar;
                if (!ignore.includes(connectedBar.id) && connectedBar.id !== trackedBarId && bars.find((b) => b.id === connectedBar.id) === undefined) {
                    if (selectedBars.find((b) => b.id === connectedBar.id) === undefined) {
                        const pufferEnd = moment(bar.end).add(connection.puffer);
                        if (connectedBar.start.isBefore(pufferEnd)) {
                            connectedBar.moveStart(pufferEnd);
                            toResolve.push(connectedBar);
                        } else if (connectedBar.originalStart !== undefined) {
                            if (pufferEnd.isBefore(connectedBar.originalStart)) {
                                connectedBar.moveStart(connectedBar.originalStart);
                            } else {
                                connectedBar.moveStart(pufferEnd);
                                toResolve.push(connectedBar);
                            }
                        }
                    }
                }
            });

            bar.linkConnections.forEach((connection) => {
                const connectedBar = connection.fromBar;
                if (!ignore.includes(connectedBar.id) && connectedBar.id !== trackedBarId && bars.find((b) => b.id === connectedBar.id) === undefined) {
                    if (selectedBars.find((b) => b.id === connectedBar.id) === undefined) {
                        const pufferStart = moment(bar.start).subtract(connection.puffer);
                        if (connectedBar.end.isAfter(pufferStart)) {
                            connectedBar.moveEnd(pufferStart);
                            toResolve.push(connectedBar);
                        } else if (connectedBar.originalEnd !== undefined) {
                            if (pufferStart.isAfter(connectedBar.originalEnd)) {
                                connectedBar.moveEnd(connectedBar.originalEnd);
                            } else {
                                connectedBar.moveEnd(pufferStart);
                                toResolve.push(connectedBar);
                            }
                        }
                    }
                }
            });
        });

        if (toResolve.length > 0) this.resolveConnections(toResolve, ignore, trackedBarId, selectedBars);
    }
}

class Row {
    constructor() {
        this.bars = []; // An array to hold all the bars
        this.childRows = [];
        this.extended = true;
        this.calendar = new GanttCalendar();
        this.useCalendarForChildren = false;
    }

    addChild(row) {
        this.childRows.push(row);
        row.parent = this;
        if (this.summaryBar === undefined) {
            let bar = new Bar(moment(), moment());
            bar.id = ++CURR_BAR_ID;
            bar.type = 'summary';
            this.bars.push(bar);
            this.summaryBar = bar;
        }
    }

    removeRow(row) {
        const index = this.childRows.indexOf(row);
        if (index > -1) {
            this.childRows.splice(index, 1);
        }
    }

    addBar(bar) {
        this.bars.push(bar);
        bar.id = ++CURR_BAR_ID;
        bar.row = this;
        bar.type = 'task';
        return bar;
    }

    removeBar(bar) {
        const index = this.bars.indexOf(bar);
        if (index > -1) {
            this.bars.splice(index, 1);
        }
    }

    getExceptionsInRange(startDate, endDate) {
        let exceptionsInRange = [];

        // Iterate through the exceptions in the calendar
        this.calendar.exceptions.forEach((exception) => {
            if (exception.recurring) {
                let subId = 1;
                const exEnd = exception.recurringEnd !== undefined ? moment.min(endDate, exception.recurringEnd) : endDate;
                // Handle recurring exceptions
                let currentExceptionStart = moment(exception.start);
                let currentExceptionEnd = moment(exception.end);

                const incrementException = () => {
                    // Move the exception to the next occurrence based on its type
                    switch (exception.type) {
                        case ExceptionType.DAILY:
                            currentExceptionStart.add(1, 'days');
                            currentExceptionEnd.add(1, 'days');
                            break;
                        case ExceptionType.WEEKLY:
                            currentExceptionStart.add(1, 'weeks');
                            currentExceptionEnd.add(1, 'weeks');
                            break;
                        case ExceptionType.MONTHLY:
                            currentExceptionStart.add(1, 'months');
                            currentExceptionEnd.add(1, 'months');
                            break;
                        case ExceptionType.YEARLY:
                            currentExceptionStart.add(1, 'years');
                            currentExceptionEnd.add(1, 'years');
                            break;
                        default: // Shouldn't come here anytime
                            currentExceptionStart = moment(exEnd.add(1, 'days'));
                            break;
                    }
                    subId++;
                };

                while (currentExceptionEnd.isBefore(startDate)) {
                    incrementException();
                }

                while (currentExceptionStart.isSameOrBefore(exEnd)) {
                    if (moment(currentExceptionEnd).isSameOrAfter(startDate) && moment(currentExceptionStart).isSameOrBefore(endDate)) {
                        let virtualException = new GanttException(moment(currentExceptionStart), moment(currentExceptionEnd));
                        virtualException.id = exception.id;
                        virtualException.subId = subId;
                        exceptionsInRange.push(virtualException);
                    }

                    incrementException();
                }
            } else {
                // Check if the exception overlaps with the given date range
                if (moment(exception.end).isSameOrAfter(startDate) && moment(exception.start).isSameOrBefore(endDate)) {
                    exceptionsInRange.push(exception);
                }
            }
        });

        return exceptionsInRange;
    }

    getOverlapDuration(exceptions) {
        if (!Array.isArray(exceptions) || exceptions.length === 0) {
            return moment.duration(0);
        }

        // Sort the exceptions by their start time
        exceptions.sort((a, b) => a.start - b.start);

        let overlapDuration = 0;
        let maxEnd = exceptions[0].end;

        for (let i = 1; i < exceptions.length; i++) {
            const current = exceptions[i];

            // If there's an overlap, add it to the total overlap duration
            if (current.start.isBefore(maxEnd)) {
                const overlap = maxEnd.diff(current.start);
                overlapDuration += overlap;

                // Update the maximum end time if the current end time is greater
                if (current.end.isAfter(maxEnd)) {
                    maxEnd = current.end;
                }
            } else {
                maxEnd = current.end;
            }
        }

        return moment.duration(overlapDuration);
    }

    effectiveTimeBetween(startDate, endDate) {
        // Calculate the duration between startDate and endDate
        let duration = moment.duration(endDate.diff(startDate));

        // Helper function to subtract overlapping durations from the total duration
        const subtractOverlap = (overlapStart, overlapEnd) => {
            let overlapDuration = moment.duration(overlapEnd.diff(overlapStart));
            duration.subtract(overlapDuration);
        };

        // Iterate through the exceptions in the row's calendar
        this.calendar.exceptions.forEach((exception) => {
            if (exception.recurring) {
                const exEnd = exception.recurringEnd !== undefined ? moment.min(endDate, exception.recurringEnd) : endDate;
                // Handle recurring exceptions
                let currentExceptionStart = moment(exception.start);
                let currentExceptionEnd = moment(exception.end);

                const incrementException = () => {
                    // Move the exception to the next occurrence based on its type
                    switch (exception.type) {
                        case ExceptionType.DAILY:
                            currentExceptionStart.add(1, 'days');
                            currentExceptionEnd.add(1, 'days');
                            break;
                        case ExceptionType.WEEKLY:
                            currentExceptionStart.add(1, 'weeks');
                            currentExceptionEnd.add(1, 'weeks');
                            break;
                        case ExceptionType.MONTHLY:
                            currentExceptionStart.add(1, 'months');
                            currentExceptionEnd.add(1, 'months');
                            break;
                        case ExceptionType.YEARLY:
                            currentExceptionStart.add(1, 'years');
                            currentExceptionEnd.add(1, 'years');
                            break;
                        default: // Shouldn't come here anytime
                            currentExceptionStart = moment(exEnd.add(1, 'days'));
                            break;
                    }
                };

                while (currentExceptionEnd.isBefore(startDate)) {
                    incrementException();
                }

                while (currentExceptionStart.isSameOrBefore(exEnd)) {
                    if ((startDate.isSameOrBefore(currentExceptionStart) && exEnd.isSameOrAfter(currentExceptionStart)) || (startDate.isSameOrBefore(currentExceptionEnd) && exEnd.isSameOrAfter(currentExceptionEnd)) || (startDate.isSameOrAfter(currentExceptionStart) && exEnd.isSameOrBefore(currentExceptionEnd))) {
                        let overlapStart = startDate.isSameOrBefore(currentExceptionStart) ? currentExceptionStart : startDate;
                        let overlapEnd = exEnd.isSameOrAfter(currentExceptionEnd) ? currentExceptionEnd : exEnd;

                        // Subtract the overlapping duration from the total duration
                        subtractOverlap(overlapStart, overlapEnd);
                    }

                    incrementException();
                }
            } else {
                // Handle non-recurring exceptions
                if ((startDate.isSameOrBefore(exception.start) && endDate.isSameOrAfter(exception.start)) || (startDate.isSameOrBefore(exception.end) && endDate.isSameOrAfter(exception.end)) || (startDate.isSameOrAfter(exception.start) && endDate.isSameOrBefore(exception.end))) {
                    let overlapStart = startDate.isSameOrBefore(exception.start) ? exception.start : startDate;
                    let overlapEnd = endDate.isSameOrAfter(exception.end) ? exception.end : endDate;

                    // Subtract the overlapping duration from the total duration
                    subtractOverlap(overlapStart, overlapEnd);
                }
            }
        });

        // Return the effective time between startDate and endDate
        return duration;
    }
}

class Bar {
    constructor(start, end, effectiveTime = 0) {
        this.start = start;
        this.end = end;
        this.effectiveTime = effectiveTime;
        this.connections = [];
        this.linkConnections = [];
    }

    move(startDate, endDate, resolveConnections = false, pushBackIfException = false) {
        // use resolveConnections = true when moving a bar programatically
        if (this.effectiveTime > 0) {
            let exceptions = this.row.getExceptionsInRange(startDate, endDate);

            if (exceptions.length > 0) {
                let appliedExceptions = [];
                let newStart = moment(startDate);
                let newEnd = moment(endDate);

                let changed = true;
                while (changed) {
                    exceptions = this.row.getExceptionsInRange(newStart, newEnd);
                    changed = false;
                    for (let i = 0; i < exceptions.length; i++) {
                        const exception = exceptions[i];
                        if (appliedExceptions.some((ex) => ex.id === exception.id && ex.subId === exception.subId)) continue;

                        let applied = true;
                        if (newEnd.isBetween(exception.start, exception.end) && newStart.isBefore(exception.start)) {
                            if (pushBackIfException) {
                                newStart = moment(exception.start).subtract(this.effectiveTime, 'minutes');
                                newEnd = moment(exception.start);
                            } else {
                                newEnd.add(exception.end.diff(exception.start));
                            }
                        } else if (newStart.isBetween(exception.start, exception.end) && newEnd.isBetween(exception.start, exception.end)) {
                            if (pushBackIfException) {
                                newStart = moment(exception.start).subtract(this.effectiveTime, 'minutes');
                                newEnd = moment(exception.start);
                            } else {
                                newStart = moment(exception.end);
                                newEnd = moment(exception.end).add(this.effectiveTime, 'minutes');
                            }
                        } else if (newStart.isBetween(exception.start, exception.end) && newEnd.isAfter(exception.end)) {
                            if (pushBackIfException) {
                                newStart = moment(exception.start).subtract(this.effectiveTime, 'minutes');
                                newEnd = moment(exception.start);
                            } else {
                                newStart = moment(exception.end);
                                newEnd = moment(exception.end).add(this.effectiveTime, 'minutes');
                            }
                        } else if (newStart.isBefore(exception.start) && newEnd.isAfter(exception.end)) {
                            newEnd.add(exception.end.diff(exception.start));
                        } else {
                            applied = false;
                        }

                        if (applied) {
                            changed = true;
                            appliedExceptions.push(exception);
                        }
                    }
                }

                let discardDuration = this.row.getOverlapDuration(this.row.getExceptionsInRange(newStart, newEnd));
                newEnd.subtract(discardDuration);

                this.start = newStart;
                this.end = newEnd;
            } else {
                this.start = startDate;
                this.end = endDate;
            }
        } else {
            this.start = startDate;
            this.end = endDate;
        }

        if (resolveConnections) {
            Data.resolveConnections([this], [], [this.id], []);
        }
    }

    moveStart(newStart, flagAsMoved = true, resolveConnections = false) {
        // use resolveConnections = true when moving a bar programatically
        this.move(newStart, moment(newStart).add(this.effectiveTime, 'minutes'), resolveConnections);
        if (flagAsMoved === true) this.hasMoved = flagAsMoved;
    }

    moveEnd(newEnd, flagAsMoved = true, resolveConnections = false) {
        // use resolveConnections = true when moving a bar programatically
        this.move(moment(newEnd).subtract(this.effectiveTime, 'minutes'), newEnd, resolveConnections, true);
        if (flagAsMoved === true) this.hasMoved = flagAsMoved;
    }

    addChild(child) {
        this.children.push(child);
        child.parent = this;
    }

    removeChild(child) {
        const index = this.children.indexOf(child);
        if (index > -1) {
            this.children.splice(index, 1);
            child.parent = null;
        }
    }

    addConnectedBar(bar, puffer = moment.duration(0)) {
        const connection = new Connection(this, bar, puffer);
        connection.id = ++CURR_CONNECTION_ID;
        this.connections.push(connection);
        bar.linkConnections.push(connection);
    }

    isVisible() {
        let currRow = this.row;
        let result = true;

        while (currRow !== undefined) {
            if (!currRow.extended) {
                result = false;
                break;
            }

            currRow = currRow.parent;
        }

        return result;
    }

    simpleCopy() {
        let cloneBar = new Bar(this.start, this.end, this.effectiveTime);
        cloneBar.color = this.color;
        cloneBar.id = this.id;
        cloneBar.connections = this.connections;
        cloneBar.linkConnections = this.linkConnections;
        cloneBar.row = this.row;
        cloneBar.type = this.type;
        return cloneBar;
    }
}

class Connection {
    constructor(fromBar, toBar, puffer = moment.duration(0)) {
        this.fromBar = fromBar;
        this.toBar = toBar;
        this.puffer = puffer;
    }
}

class GanttCalendar {
    constructor() {
        this.exceptions = [];
    }

    addException(start, end, recurring = false, type = ExceptionType.ONCE, recurringEnd = undefined, message = '') {
        const exception = new GanttException(start, end, recurring, type, recurringEnd, message);
        exception.id = ++CURR_EXCEPTION_ID;
        this.exceptions.push(exception);
        return exception;
    }

    removeException(id) {
        const index = this.exceptions.findIndex((exception) => exception.id === id);
        if (index !== -1) {
            this.exceptions.splice(index, 1);
            return true;
        }
        return false;
    }
}

const ExceptionType = Object.freeze({
    DAILY: 'Daily',
    WEEKLY: 'Weekly',
    MONTHLY: 'Monthly',
    YEARLY: 'Yearly',
    ONCE: 'Once',
});

class GanttException {
    constructor(start, end, recurring = false, type = ExceptionType.ONCE, recurringEnd = undefined, message = '') {
        this.start = start;
        this.end = end;
        this.type = type;
        this.recurring = recurring;
        this.recurringEnd = recurringEnd;
        this.message = message;
    }
}

class DateLine {
    constructor(date) {
        this.date = date;
        this.live = false;
        this.minuteOffset = 0;
    }

    getDate() {
        if (this.live) {
            this.date = moment().add(this.minuteOffset, 'minutes');
        }
        return this.date;
    }
}

export { Data, Row, Bar, GanttCalendar, GanttException, ExceptionType };
