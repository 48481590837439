import React, { Component } from 'react';
import { Tooltip } from 'react-tooltip';

export default class ActionBarInput extends Component {
    constructor() {
        super();

        this.inputRef = React.createRef();
    }

    getValue() {
        return this.inputRef.current.value;
    }

    render() {
        let inputClasses = ['gantt-action-input'];
        if (this.props.token) {
            inputClasses.push('token');
        }

        let value = this.props.value;
        if (value instanceof Date) {
            value = value.toISOString().substring(0, 10);
        }

        return (
            <>
                <div
                    data-tooltip-id='action-button-tooltip'
                    data-tooltip-content={this.props.title}
                    data-tooltip-delay-show={500}
                    onClick={this.props.onClick}
                >
                    <label>{this.props.caption}</label>
                    <input
                        className={inputClasses.join(' ')}
                        type={this.props.type}
                        defaultValue={value}
                        onChange={this.props.functions.valueChanged}
                        ref={this.inputRef}
                    />
                    {this.props.suffix ? <span className={'gantt-action-input-suffix'}>{this.props.suffix}</span> : <></>}
                </div>
                <Tooltip
                    id='action-button-tooltip'
                    place='bottom'
                    effect='solid'
                />
            </>
        );
    }
}
