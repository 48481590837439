import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleChevronLeft, faCircleChevronRight } from '@fortawesome/free-solid-svg-icons';

export default class GanttViewJumpBtns extends Component {
    findEarliestAndLatestBars(rowData) {
        let earliestBar = null;
        let latestBar = null;

        function updateDates(bar) {
            if (!earliestBar || bar.start < earliestBar.start) {
                earliestBar = bar;
            }
            if (!latestBar || bar.end > latestBar) {
                latestBar = bar;
            }
        }

        function processRow(row) {
            if (row.bars) {
                row.bars.forEach((bar) => {
                    updateDates(bar);
                });
            }
            if (row.childRows) {
                row.childRows.forEach((childRow) => {
                    processRow(childRow);
                });
            }
        }

        processRow(rowData);

        return {
            earliest: earliestBar,
            latest: latestBar,
        };
    }

    render() {
        let visibleRows = this.props.data.getVisibleRows(this.props.startDate, this.props.endDate);
        let jumpBtns = [];

        for (let i = this.props.vertScroll, c = 0; i < Math.min(visibleRows.length, this.props.vertScroll + this.props.maxRows); i++, c++) {

            let elData = this.findEarliestAndLatestBars(visibleRows[i]);

            let top = c * this.props.options.rowHeight + this.props.options.rowHeight / 2;

            if (this.props.startDate > elData.earliest.end) {
                let color;
                if (elData.earliest) color = elData.earliest.color;

                let jumpBtnLeft = (
                    <div
                        key={'l' + i}
                        className='gantt-row-jump left'
                        style={{ top: top + 'px', color: color }}
                        onClick={() => {
                            this.props.functions.scrollToDate(elData.earliest.start);
                        }}
                    >
                        <FontAwesomeIcon icon={faCircleChevronLeft} />
                    </div>
                );

                jumpBtns.push(jumpBtnLeft);
            }

            if (this.props.endDate < elData.latest.start) {
                let color;
                if (elData.latest) color = elData.latest.color;

                let jumpBtnRight = (
                    <div
                        key={'r' + i}
                        className='gantt-row-jump right'
                        style={{ top: top + 'px', color: color }}
                        onClick={() => {
                            this.props.functions.scrollToDate(elData.latest.start);
                        }}
                    >
                        <FontAwesomeIcon icon={faCircleChevronRight} />
                    </div>
                );

                jumpBtns.push(jumpBtnRight);
            }
        }

        return jumpBtns;
    }
}
