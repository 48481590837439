export function calcBoxshadow(elevation) {
    let v1 = 8 * elevation;
    let v2 = 16 * elevation;
    let v3 = 0.25 * (1 - elevation);
    return v1 + 'px ' + v2 + 'px ' + v2 + 'px hsl(0deg 0% 0% / ' + v3 + ')';
}

export function calcColor(val) {
    let hue = ((val % 8) / 7) * 360;
    hue = (hue + 180) % 360;
    return 'hsl(' + hue + ', 80%, 70%)';
}
