import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import Ripple from "../effects/ripple";
import { Tooltip } from "react-tooltip";

export default class ActionBarButton extends Component {
    render() {
        let buttonClasses = ['gantt-action-button'];
        if (this.props.emphasize) {
            buttonClasses.push('emphasize');
        }

        return (
            <>
                <div className={'gantt-action-button-wrapper'} data-tooltip-id="action-button-tooltip" data-tooltip-content={this.props.title} data-tooltip-delay-show={500} onClick={this.props.onClick}>
                    <Ripple className={buttonClasses.join(' ')}>
                        <FontAwesomeIcon className="gantt-action-button-icon" icon={this.props.icon} />
                        <div className="gantt-action-button-caption">{this.props.caption}</div>
                    </Ripple>
                </div>
                <Tooltip id="action-button-tooltip" place="bottom" effect="solid" />
            </>
        );
    }
}
