import React from 'react';
import GanttBar from './gantt-bar';

export default class SummaryBar extends GanttBar {
    getInfoContent() {
        const { data } = this.props;

        let labels = [data.row.label];
        let currentRow = data.row.parent;
        while (currentRow !== undefined) {
            labels.splice(0, 0, currentRow.label);
            currentRow = currentRow.parent;
        }
        let captions = [];
        for (let i = 0; i < labels.length; i++) {
            let attr = { index: labels.length - i };
            let caption = (
                <div
                    key={i}
                    className='gantt-info-display-caption'
                    {...attr}
                >
                    {labels[i]}
                </div>
            );
            captions.push(caption);
        }
        let color = data.color;
        if (color && color.startsWith('var(--') && color.endsWith(')')) {
            const computedStyle = getComputedStyle(document.documentElement);
            const propertyName = color.substring(4, color.length - 1);
            color = computedStyle.getPropertyValue(propertyName).trim();
        }
        let splitterStyle = { backgroundColor: color };

        return (
            <div className='gantt-info-display-content'>
                <div className='gantt-info-display-captions'>{captions}</div>
                <div
                    style={splitterStyle}
                    className='gantt-info-splitter'
                ></div>
                <div className='gantt-info-field'>
                    <div className='gantt-info-field-caption'>Start:</div>
                    <div className='gantt-info-field-value'>{data.start.format('DD.MM.YYYY HH:mm')}</div>
                </div>
                <div className='gantt-info-field'>
                    <div className='gantt-info-field-caption'>Ende:</div>
                    <div className='gantt-info-field-value'>{data.end.format('DD.MM.YYYY HH:mm')}</div>
                </div>
            </div>
        );
    }

    render() {
        const { children, data, ...restProps } = this.props;
        let startPos = this.props.functions.dateToPosition(data.start);
        let endPos = this.props.functions.dateToPosition(data.end);

        let width = endPos - startPos;
        let barClasses = ['gantt-bar', 'gantt-summarybar'];
        if (this.props.ghost === 1) barClasses.push('ghost');
        if (this.props.selected === 1) barClasses.push('selected');
        let style = { left: startPos + 'px', width: width + 'px' };
        let beforeStyle = {};
        let afterStyle = {};
        if (data.color !== undefined) {
            style.backgroundColor = data.color;
            beforeStyle.borderColor = data.color + ' transparent transparent transparent';
            afterStyle.borderColor = 'transparent ' + data.color + ' transparent transparent';
        }

        return (
            <GanttBar
                style={style}
                className={barClasses.join(' ')}
                infoContent={this.getInfoContent()}
                {...restProps}
            >
                <div className='gantt-summarybar-before-after-container'>
                    <div
                        className='gantt-summarybar-before'
                        style={beforeStyle}
                    ></div>
                    <div
                        className='gantt-summarybar-after'
                        style={afterStyle}
                    ></div>
                </div>
                {children}
            </GanttBar>
        );
    }
}
