import React from 'react';
import ReactDOM from 'react-dom/client';
import GanttChart from './components/gantt-chart';
import "./css/fonts.css";
import "./css/global.css";
import "./css/custom.css";
import moment from 'moment';
import 'moment/locale/de';

const domNode = document.getElementById("root");
const root = ReactDOM.createRoot(domNode);

moment.locale('de');

root.render(<GanttChart />);