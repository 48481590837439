import React, { Component } from "react";

export default class GanttResizer extends Component {
    mouseX = 0;
    mouseY = 0;
    leftWidth = 0;

    constructor() {
        super();

        this.resizerRef = React.createRef();

        window.addEventListener("resize", () => {
            if (this.props.onResize && this.resizerRef.current && this.resizerRef.current.previousElementSibling) {
                let leftWidth = this.resizerRef.current.previousElementSibling.getBoundingClientRect().width;
                let rightWidth = this.resizerRef.current.nextElementSibling.getBoundingClientRect().width;
                this.props.onResize(leftWidth, rightWidth);
            }
        });
    }

    componentDidMount() {
        if (this.props.onResize && this.resizerRef.current && this.resizerRef.current.previousElementSibling) {
            let leftWidth = this.resizerRef.current.previousElementSibling.getBoundingClientRect().width;
            let rightWidth = this.resizerRef.current.nextElementSibling.getBoundingClientRect().width;
            this.props.onResize(leftWidth, rightWidth);
        }
    }

    triggerResize() {
        if (this.props.onResize && this.resizerRef.current && this.resizerRef.current.previousElementSibling) {
            let leftWidth = this.resizerRef.current.previousElementSibling.getBoundingClientRect().width;
            let rightWidth = this.resizerRef.current.nextElementSibling.getBoundingClientRect().width;
            this.props.onResize(leftWidth, rightWidth);
        }
    }

    mouseDownHandler = (e) => {
        e.target = e.target.parentNode;
        this.mouseX = e.clientX;
        this.mouseY = e.clientY;

        this.leftSide = e.target.previousElementSibling;
        this.rightSide = e.target.nextElementSibling;

        this.leftWidth = this.leftSide.getBoundingClientRect().width;

        document.addEventListener("mousemove", this.mouseMoveHandler);
        document.addEventListener("mouseup", this.mouseUpHandler);
    };

    mouseMoveHandler = (e) => {
        const dx = e.clientX - this.mouseX;
        // const dy = e.clientY - this.mouseY;

        const newLeftWidth = this.leftWidth + dx;
        this.leftSide.style.width = `${newLeftWidth}px`;

        document.body.style.cursor = "col-resize";

        this.leftSide.style.userSelect = "none";
        this.leftSide.style.pointerEvents = "none";

        this.rightSide.style.userSelect = "none";
        this.rightSide.style.pointerEvents = "none";

        if (this.props.onResize !== undefined) this.props.onResize(newLeftWidth, this.rightSide.clientWidth);
    };

    mouseUpHandler = (e) => {
        e.target.style.removeProperty("cursor");
        document.body.style.removeProperty("cursor");

        this.leftSide.style.removeProperty("user-select");
        this.leftSide.style.removeProperty("pointer-events");

        this.rightSide.style.removeProperty("user-select");
        this.rightSide.style.removeProperty("pointer-events");

        // Remove the handlers of `mousemove` and `mouseup`
        document.removeEventListener("mousemove", this.mouseMoveHandler);
        document.removeEventListener("mouseup", this.mouseUpHandler);
    };

    render() {
        return (
            <div
                ref={this.resizerRef}
                className='gantt-resizer-wrapper'
            >
                <div
                    className='gantt-resizer'
                    onMouseDown={this.mouseDownHandler}
                >
                    <div className='gantt-resizer-line'></div>
                </div>
            </div>
        );
    }
}
