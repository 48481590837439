import React, { Component } from 'react';
import GanttResizer from './gantt-resizer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareMinus, faSquarePlus } from '@fortawesome/free-solid-svg-icons';
import * as utils from '../scripts/utils.js';

export default class GanttTree extends Component {
    recursiveDissolve(row) {
        let result = [];
        for (let i = 0; i < row.childRows.length; i++) {
            let childRow = row.childRows[i];
            result.push(childRow);

            if (childRow.childRows.length > 0) result = result.concat(this.recursiveDissolve(childRow));
        }
        return result;
    }

    getContent = (column) => {
        const rowHeight = this.props.options.rowHeight;
        let rows = [];
        let visibleRows = this.props.data.getVisibleRows(this.props.startDate, this.props.endDate);

        for (let i = this.props.vertScroll; i < Math.min(visibleRows.length, this.props.vertScroll + this.props.maxRows); i++) {
            let data = visibleRows[i];

            let row;

            if (column === 'extend') {
                let onRowClick = (e) => {
                    visibleRows[i].extended = !visibleRows[i].extended;
                    this.props.functions.refreshGanttData();
                };

                let level = 0;
                let currRow = visibleRows[i];
                while (currRow.parent !== undefined) {
                    currRow = currRow.parent;
                    level++;
                }
                let line = [];
                for (let i = 0; i < level; i++) {
                    let lineStyle = { top: 0, right: 10 - (i % 5) * 2 + 'px', height: rowHeight + 'px', backgroundColor: utils.calcColor(i) };
                    line.push(
                        <div
                            key={i}
                            className='gantt-tree-row-line'
                            style={lineStyle}
                        ></div>
                    );
                }

                let classes = ['gantt-tree-row', 'gantt-tree-extend-row', 'row'];
                if (this.props.focusedRow === visibleRows[i].id) {
                    classes.push('focused');
                }

                if (visibleRows[i].childRows.length > 0) {
                    row = (
                        <div
                            key={i}
                            className={classes.join(' ')}
                            onClick={onRowClick}
                            style={{ minHeight: this.props.options.rowHeight, maxHeight: this.props.options.rowHeight }}
                        >
                            {line}
                            {visibleRows[i].extended === true ? <FontAwesomeIcon icon={faSquareMinus} /> : <FontAwesomeIcon icon={faSquarePlus} />}
                        </div>
                    );
                } else {
                    row = (
                        <div
                            key={i}
                            className={classes.join(' ')}
                            style={{ minHeight: this.props.options.rowHeight, maxHeight: this.props.options.rowHeight }}
                        >
                            {line}
                        </div>
                    );
                }
            } else {
                let str;
                if (column.format) {
                    if (column.id === 'start') {
                        let time = undefined;
                        if (data.summaryBar) {
                            time = this.findEarliestAndLatestDates(data).earliestDate;
                        } else {
                            for (let j = 0; j < data.bars.length; j++) {
                                if (time === undefined || data.bars[j].start < time) time = data.bars[j].start;
                            }
                        }
                        if (time) str = time.format(column.format);
                    } else if (column.id === 'end') {
                        let time = undefined;
                        if (data.summaryBar) {
                            time = this.findEarliestAndLatestDates(data).latestDate;
                        } else {
                            for (let j = 0; j < data.bars.length; j++) {
                                if (time === undefined || data.bars[j].end > time) time = data.bars[j].end;
                            }
                        }
                        if (time) str = time.format(column.format);
                    } else if (data[column.id]) {
                        str = data[column.id].format(column.format);
                    }
                } else {
                    str = data[column.id];
                }

                let classes = ['gantt-tree-row', 'row'];
                if (this.props.focusedRow === visibleRows[i].id) {
                    classes.push('focused');
                }

                row = (
                    <div
                        key={i}
                        className={classes.join(' ')}
                        style={{ minHeight: this.props.options.rowHeight, maxHeight: this.props.options.rowHeight }}
                    >
                        {str}
                    </div>
                );
            }

            rows.push(row);
        }
        return rows;
    };

    getHeaders = () => {
        let heads = [];

        let indexColumn = (
            <React.Fragment key={'idx'}>
                <div className='gantt-tree-column'>
                    <div className='gantt-tree-column-head gantt-tree-column-extend-head'>{''}</div>
                    <div className='gantt-tree-column-content'>{this.getContent('extend')}</div>
                </div>
            </React.Fragment>
        );

        heads.push(indexColumn);

        for (let i = 0; i < this.props.columns.length; i++) {
            let head = (
                <React.Fragment key={i}>
                    <div className='gantt-tree-column'>
                        <div className='gantt-tree-column-head'>{this.props.columns[i].name}</div>
                        <div className='gantt-tree-column-content'>{this.getContent(this.props.columns[i])}</div>
                    </div>
                    {i < this.props.columns.length - 1 ? <GanttResizer /> : <></>}
                </React.Fragment>
            );
            heads.push(head);
        }
        return heads;
    };

    findEarliestAndLatestDates(rowData) {
        let earliestDate = null;
        let latestDate = null;

        function updateDates(start, end) {
            if (!earliestDate || start < earliestDate) {
                earliestDate = start;
            }
            if (!latestDate || end > latestDate) {
                latestDate = end;
            }
        }

        function processRow(row) {
            if (row.bars) {
                row.bars.forEach((bar) => {
                    if (bar.type !== 'summary') updateDates(bar.start, bar.end);
                });
            }
            if (row.childRows) {
                row.childRows.forEach((childRow) => {
                    processRow(childRow);
                });
            }
        }

        processRow(rowData);

        return {
            earliestDate: earliestDate,
            latestDate: latestDate,
        };
    }

    render() {
        return (
            <div
                ref={(elem) => (this.treeRef = elem)}
                className='gantt-tree'
            >
                {this.getHeaders()}
            </div>
        );
    }
}
