import React, { Component } from 'react';

export default class DateLine extends Component {
    render() {
        const { data } = this.props;
        let pos = this.props.functions.dateToPosition(data.date);

        let style = { left: pos + 'px' };
        let captionStyle = {};

        if (data.color !== undefined) {
            style.borderColor = data.color;
            captionStyle.color = data.color;
        }

        return (
            <div
                style={style}
                className={'gantt-date-line'}
            >
                <div
                    style={captionStyle}
                    className={'gantt-date-line-caption'}
                >
                    {data.caption}
                </div>
            </div>
        );
    }
}
