import React, { Component } from 'react';
import moment from 'moment';
import GanttViewHeadDay from './gantt-view-head-day';

export default class GanttViewHead extends Component {
    constructor() {
        super();

        this.headRef = React.createRef();
    }

    getHours() {
        let deltaDays = this.props.endDate.diff(this.props.startDate, 'days') + 1;

        this.hours = [];
        let currDate = moment(this.props.startDate);
        let hourWidth = this.props.options.dayWidth / 24;

        for (let i = 0; i < deltaDays; i++) {
            for (let h = 0; h < 24; h++) {
                let hourDate = moment(currDate).startOf('day').add(h, 'hours');
                let hour = (
                    <div
                        key={'h' + i + 't' + h}
                        className='gantt-view-head-hour'
                        style={{ minWidth: hourWidth, maxWidth: hourWidth }}
                    >
                        <div className='gantt-view-head-hour-txt'>{hourDate.format('HH:mm')}</div>
                    </div>
                );
                this.hours.push(hour);
            }
            currDate.add(1, 'days');
        }
        return this.hours;
    }

    getDays() {
        let deltaDays = this.props.endDate.diff(this.props.startDate, 'days') + 1;

        this.days = [];
        let currDate = moment(this.props.startDate);
        for (let i = 0; i < deltaDays; i++) {
            let dayRef = React.createRef();
            let day = (
                <GanttViewHeadDay
                    ref={dayRef}
                    key={'d' + i}
                    date={moment(currDate)}
                    options={this.props.options}
                />
            );
            this.days.push(day);
            currDate.add(1, 'days');
        }
        return this.days;
    }

    getMonths() {
        let dateWidth = this.props.options.dayWidth;
        let deltaDays = this.props.endDate.diff(this.props.startDate, 'days') + 1;

        let months = [];
        let currDate = moment(this.props.startDate);
        let monthDays = 1;
        for (let i = 0; i < deltaDays; i++) {
            if (i === deltaDays - 1 || moment(currDate).add(1, 'days').month() !== currDate.month()) {
                let monthWidth = monthDays * dateWidth + 'px';
                let month = (
                    <div
                        key={'m' + i}
                        className='gantt-view-head-month'
                        style={{ minWidth: monthWidth, maxWidth: monthWidth }}
                    >
                        <div className='gantt-view-head-month-txt'>{currDate.format('MMMM')}</div>
                    </div>
                );
                months.push(month);
                monthDays = 0;
            }
            currDate.add(1, 'days');
            monthDays++;
        }
        return months;
    }

    getYears() {
        let dateWidth = this.props.options.dayWidth;
        let deltaDays = this.props.endDate.diff(this.props.startDate, 'days') + 1;

        let years = [];
        let currDate = moment(this.props.startDate);
        let yearDays = 1;
        for (let i = 0; i < deltaDays; i++) {
            if (i === deltaDays - 1 || moment(currDate).add(1, 'days').year() !== currDate.year()) {
                let yearWidth = yearDays * dateWidth + 'px';
                let year = (
                    <div
                        key={'y' + i}
                        className='gantt-view-head-year'
                        style={{ minWidth: yearWidth, maxWidth: yearWidth }}
                    >
                        <div className='gantt-view-head-year-txt'>{currDate.format('YYYY')}</div>
                    </div>
                );
                years.push(year);
                yearDays = 0;
            }
            currDate.add(1, 'days');
            yearDays++;
        }
        return years;
    }

    findDay(date) {
        for (let i = 0; i < this.days.length; i++) {
            if (this.days[i].props.date.isSame(date, 'day')) {
                return this.days[i];
            }
        }
        return undefined;
    }

    componentDidMount() {
        let date = this.findDay(moment());
        if (date !== undefined) {
            this.headRef.current.style.overflow = 'auto';
            this.headRef.current.scrollLeft = this.headRef.current.scrollWidth / 2 - this.headRef.current.offsetWidth / 2;
            this.headRef.current.style.overflow = 'hidden';
        }
    }

    getHourHead() {
        if (this.props.options.dayWidth > 450) {
            return (
                <div
                    className='gantt-view-head-hours'
                    style={{ transform: `translateX(${this.props.offset}px)` }}
                >
                    {this.getHours()}
                </div>
            );
        }
        return <></>;
    }

    getMonthHead() {
        if (this.props.options.dayWidth <= 450) {
            return <div className='gantt-view-head-months'>{this.getMonths()}</div>;
        }
        return <></>;
    }

    getYearHead() {
        if (this.props.options.dayWidth <= 450) {
            return <div className='gantt-view-head-years'>{this.getYears()}</div>;
        }
        return <></>;
    }

    render() {
        return (
            <div
                ref={this.headRef}
                className='gantt-view-head'
            >
                {this.getYearHead()}
                {this.getMonthHead()}
                <div
                    className='gantt-view-head-days'
                    style={{ transform: `translateX(${this.props.offset}px)` }}
                >
                    {this.getDays()}
                </div>
                {this.getHourHead()}
            </div>
        );
    }
}
