import { faGear, faMagnifyingGlassMinus, faMagnifyingGlassPlus, faPause, faPersonWalking, faPlay, faRotateLeft, faRotateRight, faSave, faStop, faTruckFast } from '@fortawesome/free-solid-svg-icons';
import React, { Component } from 'react';
import ActionBarButton from './action-bar-button';
import ActionBarInput from './action-bar-input';
import ActionBarSlider from './action-bar-slider';

export default class ActionBar extends Component {
    state = {
        inputChanged: false,
        settingsVisible: false,
    };

    constructor() {
        super();

        this.tokenRef = React.createRef();
        this.startRef = React.createRef();
        this.endRef = React.createRef();
        this.speedRef = React.createRef();
        this.zoomRef = React.createRef();
    }

    revert = () => {
        this.props.data.revertHistory();
        this.props.functions.refreshGanttData();
    };

    restore = () => {
        this.props.data.restoreHistory();
        this.props.functions.refreshGanttData();
    };

    valueChanged = () => {
        this.setState({ inputChanged: true });
    };

    saveShopfloorValues = () => {
        const token = this.tokenRef.current.getValue();
        const start = this.startRef.current.getValue();
        const end = this.endRef.current.getValue();
        const speed = this.speedRef.current.getValue();
        const zoom = this.zoomRef.current.getValue();

        localStorage.setItem('sf_token', token);
        localStorage.setItem('sf_anim_start', start);
        localStorage.setItem('sf_anim_end', end);
        localStorage.setItem('sf_anim_speed', speed);
        localStorage.setItem('sf_zoom', zoom);

        window.location.reload();
    };

    toggleShopfloorSettings = () => {
        if (!this.state.settingsVisible) this.props.functions.pauseAnimation();
        this.setState({ settingsVisible: !this.state.settingsVisible });
    };

    getSettings = () => {
        if (this.state.settingsVisible) {
            const inputFunctions = { valueChanged: this.valueChanged };
            return (
                <div className='shopfloor-settings'>
                    <ActionBarInput
                        type={'text'}
                        caption={'Token: '}
                        // title={'Token hier einfügen'}
                        token={true}
                        value={localStorage.getItem('sf_token')}
                        functions={inputFunctions}
                        ref={this.tokenRef}
                    />
                    <ActionBarInput
                        type={'number'}
                        caption={'Zeige die letzen'}
                        suffix={'Tage'}
                        // title={'Zeitraum der Anzeige festlegen'}
                        value={localStorage.getItem('sf_anim_start')}
                        functions={inputFunctions}
                        ref={this.startRef}
                    />
                    <ActionBarInput
                        type={'number'}
                        caption={'Zeige die nächsten'}
                        suffix={'Tage'}
                        // title={'Zeitraum der Anzeige festlegen'}
                        value={localStorage.getItem('sf_anim_end')}
                        functions={inputFunctions}
                        ref={this.endRef}
                    />
                    <ActionBarSlider
                        caption={'Geschwindigkeit:'}
                        startIcon={faPersonWalking}
                        endIcon={faTruckFast}
                        levels={5}
                        defaultValue={2}
                        value={localStorage.getItem('sf_anim_speed')}
                        ref={this.speedRef}
                    />

                    <ActionBarSlider
                        caption={'Zoom:'}
                        startIcon={faMagnifyingGlassMinus}
                        endIcon={faMagnifyingGlassPlus}
                        levels={4}
                        defaultValue={1}
                        value={localStorage.getItem('sf_zoom')}
                        ref={this.zoomRef}
                    />

                    <ActionBarButton
                        icon={faSave}
                        caption={'Speichern'}
                        title={'Werte speichern'}
                        onClick={this.saveShopfloorValues}
                        emphasize={this.state.inputChanged}
                    />
                </div>
            );
        }
        return <></>;
    };

    render() {
        return (
            <div className='gantt-action-bar'>
                <div className='left'>
                    <ActionBarButton
                        icon={faRotateLeft}
                        caption={'Strg+Z'}
                        title={'Rückgängig'}
                        onClick={this.revert}
                    />
                    <ActionBarButton
                        icon={faRotateRight}
                        caption={'Strg+Y'}
                        title={'Wiederherstellen'}
                        onClick={this.restore}
                    />
                </div>
                <div className='middle'>
                    <ActionBarButton
                        icon={faPlay}
                        caption={'Start'}
                        title={'Animation starten'}
                        onClick={this.props.functions.startAnimation}
                        emphasize={this.props.isStarted && !this.props.isPaused}
                    />
                    <ActionBarButton
                        icon={faPause}
                        caption={'Pause'}
                        title={'Animation pausieren'}
                        onClick={this.props.functions.pauseAnimation}
                        emphasize={this.props.isPaused}
                    />
                    <ActionBarButton
                        icon={faStop}
                        caption={'Stop'}
                        title={'Animation stoppen'}
                        onClick={this.props.functions.stopAnimation}
                    />
                </div>
                <div className='right'>
                    {this.getSettings()}

                    <ActionBarButton
                        icon={faGear}
                        caption={'Einstellungen'}
                        title={'Einstellungen'}
                        onClick={this.toggleShopfloorSettings}
                        emphasize={this.state.settingsVisible || localStorage.getItem('sf_token') === null}
                    />
                </div>
            </div>
        );
    }
}
