import React, { Component } from 'react';
import GanttTree from './gantt-tree';
import GanttResizer from './gantt-resizer';
import GanttView from './gantt-view';
import ActionBar from './actionbar/action-bar';
import '../css/gantt.css';
import '../css/shopfloor.css';
import moment from 'moment';
import * as Gantt from '../scripts/GanttChartData.js';
import InformationWindow from './information-window';

export default class GanttChart extends Component {
    state = {
        mousePosition: { x: 0, y: 0 },
        startDate: moment().startOf('day'),
        initDate: moment().startOf('day'),
        numDays: 0,
        options: {
            rowHeight: 40,
            dayWidth: 100,
        },
        vertScroll: 0,
        maxRows: 0,
        infoWindowVisible: false,
        infoWindowContent: undefined,
        focusedRow: undefined,
        animPause: false,
    };
    internalMousePos = { x: 0, y: 0 };
    ganttData;
    ganttOptions = {
        dragBars: false,
    };
    liveEnabled = false;
    liveInterval;
    animate = false;
    animationInterval;
    animationIndex = 0;
    animationBarIndex = 0;
    viewWidth;

    token;
    animStart;
    animEnd;

    constructor() {
        super();

        this.resizerRef = React.createRef();
        this.viewRef = React.createRef();
        this.blobRef = React.createRef();

        this.ganttData = new Gantt.Data();
        this.ganttData.hideInvisibleBars = true; // Shopfloor
        this.animate = true; // Shopfloor

        this.dataGroupBy = 'resource';
        this.dataOrder = 'asc';

        this.loadData();
    }

    componentWillUnmount() {
        if (this.liveInterval) {
            clearInterval(this.liveInterval);
        }

        if (this.animate) {
            clearInterval(this.animationInterval);
        }
    }

    addLiveTimeline(minuteOffset) {
        let dateLine = this.ganttData.addLiveDateLine(minuteOffset);
        if (!this.liveEnabled) {
            this.liveEnabled = true;
            this.liveInterval = setInterval(() => {
                this.refreshGanttData();
            }, 60000);
        }
        return dateLine;
    }

    loadData() {
        let dateLine = this.addLiveTimeline();
        dateLine.color = 'var(--tertiary)';
        dateLine.caption = 'Jetzt';

        const param = new URL(window.location.href).pathname.substring(1);

        let token = localStorage.getItem('sf_token');
        if (param.length > 0) {
            token = param;
        }

        if (token) {
            this.fetchTokenData(token)
                .then((data) => {
                    let ansicht = -1;
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].ansicht !== undefined) {
                            ansicht = data[i].ansicht;
                            data.splice(i, 1);
                            break;
                        }
                    }

                    if (ansicht === 0) {
                        this.dataGroupBy = 'resource';
                    } else {
                        this.dataGroupBy = 'beleg';
                    }
                    this.sortData(data);
                    this.displayData(data);
                    this.refreshGanttData();
                    this.startAnimation();
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }

    initAnimation() {
        if (this.animate) {
            this.animationIndex = -1;
            this.animationBarIndex = 0;
            if (this.animationInterval) clearInterval(this.animationInterval);

            let speedLevel = localStorage.getItem('sf_anim_speed');
            speedLevel = speedLevel ? parseInt(speedLevel) : 2;

            let animTime;
            switch (speedLevel) {
                default: {
                    animTime = 60000;
                    break;
                }
                case 0: {
                    animTime = 60000;
                    break;
                }
                case 1: {
                    animTime = 30000;
                    break;
                }
                case 2: {
                    animTime = 15000;
                    break;
                }
                case 3: {
                    animTime = 10000;
                    break;
                }
                case 4: {
                    animTime = 5000;
                    break;
                }
            }

            const animationFunc = () => {
                if (this.state.animPause) return;
                let reload = false;
                let reloadAvailable = false;
                let scroll = this.animationIndex >= 0;

                let visibleRows = this.ganttData.getVisibleRows(this.calcStartDate(), this.calcEndDate());
                if (visibleRows.length > 0) {
                    if (this.animationIndex < 0) {
                        this.animationIndex = 0;
                        this.animationBarIndex = 0;
                    } else {
                        if (visibleRows[this.animationIndex].bars && this.animationBarIndex < visibleRows[this.animationIndex].bars.length - 1) {
                            this.animationBarIndex++;
                        } else {
                            this.animationIndex++;
                            if (this.animationIndex >= visibleRows.length) {
                                this.animationIndex = 0;
                                reload = true;
                            }
                            this.animationBarIndex = 0;
                        }
                    }

                    while (!visibleRows[this.animationIndex].bars || (visibleRows[this.animationIndex].bars.length === 1 && visibleRows[this.animationIndex].summaryBar)) {
                        this.animationIndex++;
                        if (this.animationIndex >= visibleRows.length) this.animationIndex = 0;
                        this.animationBarIndex = 0;
                    }

                    const rowIndex = this.animationIndex;
                    const barIndex = this.animationBarIndex;

                    this.setState({ focusedRow: visibleRows[rowIndex].id, focusStart: true }, () => {
                        const fRow = visibleRows[rowIndex];
                        if (fRow.bars && fRow.bars.length > 0) {
                            if (scroll) {
                                this.scrollToDate(fRow.bars[barIndex].start, undefined, undefined, () => {
                                    if (reload) {
                                        if (reloadAvailable) {
                                            window.location.reload();
                                        } else {
                                            reloadAvailable = true;
                                        }
                                    }
                                });
                            } else {
                                this.setInitDate(fRow.bars[barIndex].start);
                            }

                            this.scrollRowIntoView(rowIndex, () => {
                                if (reload) {
                                    if (reloadAvailable) {
                                        window.location.reload();
                                    } else {
                                        reloadAvailable = true;
                                    }
                                }
                            });
                        }
                    });
                }
            };
            animationFunc();
            let startTime = moment();
            let oldDelta;
            let atStart = true;
            let wasPaused = false;
            this.animationInterval = setInterval(() => {
                let delta = moment().diff(startTime, 'milliseconds');
                if (this.state.animPause) {
                    wasPaused = true;
                    delta = oldDelta;
                } else {
                    if (wasPaused) {
                        startTime = moment().subtract(oldDelta, 'milliseconds');
                        delta = oldDelta;
                    }
                    wasPaused = false;
                }

                if (delta >= animTime / 2 && atStart) {
                    atStart = false;
                    this.blobRef.current.style.backgroundColor = 'var(--secondary)';
                    this.setState({ focusStart: false }, () => {
                        const visibleRows = this.ganttData.getVisibleRows(this.calcStartDate(), this.calcEndDate());
                        if (visibleRows.length > 0) {
                            const fRow = visibleRows[this.animationIndex];
                            if (fRow.bars && fRow.bars.length > 0) {
                                this.scrollToDate(fRow.bars[this.animationBarIndex].end);
                            }
                        }
                    });
                }
                if (delta >= animTime) {
                    animationFunc();
                    startTime = moment();
                    atStart = true;
                    this.blobRef.current.style.backgroundColor = 'var(--primary)';
                }

                let progress = delta / animTime;
                if (progress > 1) progress = 1;
                this.blobRef.current.style.transform = 'translate(-50%, -50%) scale(' + progress + ')';

                oldDelta = delta;
            }, 16);
            this.forceUpdate();
        }
    }

    startAnimation = () => {
        if (this.state.animPause) {
            this.setState({ animPause: false });
        } else if (!this.animationInterval) {
            this.initAnimation();
        }
    };

    pauseAnimation = () => {
        if (this.animationInterval) {
            this.setState({ animPause: true });
        }
    };

    stopAnimation = () => {
        if (this.animationInterval) {
            clearInterval(this.animationInterval);
            this.animationInterval = undefined;
            this.animationIndex = -1;
            this.animationBarIndex = 0;
            this.setState({ focusedRow: undefined, focusStart: false, animPause: false });
        }
    };

    /**
     * Get the earliest start time from an item.
     *
     * @param {Object} item - The item object.
     * @returns {moment} - The earliest start moment.
     */
    getEarliestStartTime(item) {
        // Get the earliest start time from the item's bars
        let earliestTime = item.bars && item.bars.length > 0 ? moment.min(item.bars.map((bar) => moment(bar.start))) : moment.max();

        // If the item has children, find the earliest start time amongst the children
        if (item.children && item.children.length > 0) {
            const childTime = moment.min(item.children.map(this.getEarliestStartTime));
            earliestTime = moment.min([earliestTime, childTime]);
        }

        return earliestTime;
    }

    /**
     * Recursive function to sort the tree.
     *
     * @param {Array} tree - The tree array.
     */
    sortTree(tree) {
        if (!tree || tree.length === 0) return;

        // Sort the tree based on the earliest start times
        tree.sort((a, b) => {
            const timeA = this.getEarliestStartTime(a);
            const timeB = this.getEarliestStartTime(b);
            return timeA.isBefore(timeB) ? -1 : timeA.isAfter(timeB) ? 1 : 0;
        });

        // Add order property to each item
        tree.forEach((item, index) => {
            item.order = index;

            // Recursively sort children
            if (item.children) {
                this.sortTree(item.children);
            }
        });
    }

    sortData(data) {
        this.sortTree(data);
    }

    displayData(data) {
        for (let i = 0; i < data.length; i++) {
            this.insertRow(data[i]);
        }
    }

    getLabel(rowData) {
        let row;
        let groupBy;
        let isParent;
        if (rowData.children && rowData.children.length > 0) {
            row = rowData.children[0];
            groupBy = this.dataGroupBy;
            isParent = true;
        } else if (rowData.bars && rowData.bars.length > 0) {
            row = rowData;
            groupBy = this.dataGroupBy === 'resource' ? 'orderConfirmation' : 'resource';
            isParent = false;
        } else {
            return 'Invalid';
        }

        if (row && row.bars && row.bars.length > 0) {
            const bar = row.bars[0];
            let label;
            if (groupBy === 'resource') {
                label = bar.employeeNo ? bar.employeeNo + ' - ' + bar.employeeName : bar.costCenterNo + ' - ' + bar.costCenterName;
            } else {
                label = bar.orderConfirmationNo ? (bar.orderConfirmationNo === -3 ? (isParent ? 'Termine' : 'Termin') : 'Auftrag ' + bar.orderConfirmationNo + (bar.orderConfirmationPos > 0 ? '.' + bar.orderConfirmationPos : '')) : bar.projectName;
            }

            let articleObj = <></>;
            let activityObj = <></>;
            if (!isParent) {
                if (bar.articleDesc && bar.articleDesc.trim().length > 0) articleObj = <span className={'article-tag'}>{bar.articleNo}</span>;
                if (bar.activityDesc && bar.activityDesc.trim().length > 0) activityObj = <span className={'activity-tag'}>{bar.activityDesc}</span>;
            }

            let labelObj = <div className={'row-label-div'}>{label}{articleObj}{activityObj}</div>;
            return labelObj;
        }
    }

    insertRow(rowData) {
        let row = this.ganttData.addRow();
        if (rowData.label) {
            row.label = rowData.label;
        } else {
            row.label = this.getLabel(rowData);
        }

        if (rowData.children) {
            for (let i = 0; i < rowData.children.length; i++) {
                let childRow = this.insertRow(rowData.children[i]);
                row.addChild(childRow);
            }
        }

        if (rowData.bars) {
            this.insertBars(rowData.bars, row);
        }

        return row;
    }

    insertBars(barsData, row) {
        for (let i = 0; i < barsData.length; i++) {
            this.insertBar(barsData[i], row);
        }
    }

    insertBar(barData, row) {
        if (barData.type === 'task') {
            let start = moment(barData.start);
            let end = moment(barData.end);
            let diff = end.diff(start, 'minutes');
            let bar = new Gantt.Bar(start, end, diff);
            if (barData.color && barData.color !== '#000000') bar.color = barData.color;
            row.addBar(bar);
        } else if (barData.type === 'summary') {
            row.summaryBar.color = barData.color;
        }
    }

    async fetchTokenData(token) {
        try {
            const url = window.location.protocol + '//' + window.location.hostname + '/data/get_data.php';
            const body = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: `token=${encodeURIComponent(token)}`,
            };
            const response = await fetch(url, body);
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    }

    componentDidMount() {
        let zoom = localStorage.getItem('sf_zoom');
        if (zoom) {
            zoom = parseInt(zoom);
            switch (zoom) {
                case 0:
                    this.updateOptions({ dayWidth: 30 });
                    break;
                case 1:
                    this.updateOptions({ dayWidth: 100 });
                    break;
                case 2:
                    this.updateOptions({ dayWidth: 460 });
                    break;
                case 3:
                    this.updateOptions({ dayWidth: 1200 });
                    break;
                default:
                    this.updateOptions({ dayWidth: 100 });
            }
        }

        this.refreshGanttData();
        this.ganttData.pushHistory();
    }

    handleMouseMove = (e) => {
        const { clientX, clientY } = e;
        this.internalMousePos = { x: clientX, y: clientY };
        if (this.state.infoWindowVisible) {
            this.setState({ mousePosition: this.internalMousePos });
        }
    };

    updateOptions = (alteredProperties) => {
        let newOptions = this.state.options;
        for (const [key, value] of Object.entries(alteredProperties)) {
            newOptions[key] = value;
        }
        this.setState({ options: newOptions });
        this.refreshGanttData();
    };

    refreshGanttData = () => {
        this.doVertScroll(0, () => {
            this.resizerRef.current.triggerResize();
        });
    };

    setInitDate = (date) => {
        this.setState({
            initDate: date.startOf('day'),
        });
    };

    setInitDateExact = (date) => {
        this.setState({
            initDate: date,
        });
    };

    incInitDate = (val) => {
        const mins = Math.round(val * 1440);
        this.setState((state) => ({
            initDate: moment(state.initDate).add(mins, 'minutes'),
        }));
    };

    calcStartDate() {
        let numDays = Math.ceil(this.state.numDays / 2);
        return moment(this.state.initDate).subtract(numDays, 'days');
    }

    calcEndDate() {
        let numDays = Math.ceil(this.state.numDays / 2);
        return moment(this.state.initDate).add(numDays, 'days');
    }

    calcViewStartDate() {
        if (this.viewWidth) {
            const totalDaysFromCenter = this.viewWidth / this.state.options.dayWidth / 2;
            const minutesFromCenter = totalDaysFromCenter * 24 * 60;
            const viewStart = moment(this.state.initDate).add(12, 'hours').subtract(minutesFromCenter, 'minutes');
            return viewStart;
        } else {
            return moment();
        }
    }

    calcViewEndDate() {
        if (this.viewWidth) {
            const totalDaysFromCenter = this.viewWidth / this.state.options.dayWidth / 2;
            const minutesFromCenter = totalDaysFromCenter * 24 * 60;
            const viewStart = moment(this.state.initDate).add(12, 'hours').add(minutesFromCenter, 'minutes');
            return viewStart;
        } else {
            return moment();
        }
    }

    onResize = (leftWidth, rightWidth) => {
        this.viewWidth = rightWidth;
        let numDays = Math.ceil(rightWidth / this.state.options.dayWidth) + 2;
        if (numDays % 2 !== 0) numDays++;
        this.setState({ numDays: numDays });
    };

    setVertScroll = (value) => {
        this.setState({ vertScroll: value });
    };

    scrollRowIntoView = (value, afterScroll) => {
        let clientHeight = this.viewRef.current.viewContentRef.current.contentRef.current.clientHeight;
        let maxRows = Math.floor(clientHeight / this.state.options.rowHeight) + 1;
        let minVal = this.state.vertScroll;
        let maxVal = this.state.vertScroll + maxRows;
        let animTime = 200;

        if (value >= maxVal - 12 || value <= minVal + 3) {
            let targetPos = Math.ceil(value - maxRows / 2);
            let diff = Math.abs(this.state.vertScroll - targetPos);
            animTime = 700 / diff;
            const intervalFunc = () => {
                let dir = 0;
                if (this.state.vertScroll < targetPos) dir = 1;
                else if (this.state.vertScroll > targetPos) dir = -1;

                if (dir !== 0) {
                    let prevVal = this.state.vertScroll;
                    this.doVertScroll(dir, () => {
                        if (this.state.vertScroll === prevVal) {
                            clearInterval(tmpInterval);
                            if (afterScroll) afterScroll();
                        }
                    });
                } else {
                    clearInterval(tmpInterval);
                    if (afterScroll) afterScroll();
                }
            };
            let tmpInterval = setInterval(intervalFunc, animTime);
            intervalFunc();
        } else {
            if (afterScroll) afterScroll();
        }
    };

    doVertScroll = (delta, afterScroll) => {
        let clientHeight = this.viewRef.current.viewContentRef.current.contentRef.current.clientHeight;
        let visibleRows = this.ganttData.getVisibleRows(this.calcStartDate(), this.calcEndDate());
        let maxRows = Math.floor(clientHeight / this.state.options.rowHeight) + 1;
        let maxScroll = Math.max(0, visibleRows.length - maxRows + 1);
        this.setState(
            (state) => ({ vertScroll: Math.min(maxScroll, Math.max(0, state.vertScroll + delta)), maxRows: maxRows }),
            () => {
                if (afterScroll) afterScroll();
            }
        );
    };

    setInfoWindowVisibility = (visible) => {
        this.setState({ infoWindowVisible: visible, mousePosition: this.internalMousePos });
    };

    setInfoWindowContent = (content) => {
        this.setState({ infoWindowContent: content });
    };

    scrollToDate = (date, scrollBehaviour, animationTime, afterScroll) => {
        // convert both dates to Moment objects for easier calculation
        const endDate = moment(date).startOf('day');

        // calculate the number of days between start and end
        const diffMinutes = endDate.diff(this.state.initDate, 'minutes');

        let startTime = moment().valueOf();

        const animTime = animationTime ? animationTime : 1000;

        this.setState({ isScrolling: true });

        const intervalId = setInterval(() => {
            const t = ((moment().valueOf() - startTime) * (1000 / animTime)) / 1000; // Normalize currentStep to a range of 0 - 1
            if (t <= 1) {
                // Quadratic in-out easing function
                let easedT;
                if (scrollBehaviour === 'linear') {
                    easedT = t;
                } else {
                    easedT = t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
                }
                // let easedT = t;

                // calculate number of days to move by multiplying total diffDays by easedT
                const minutesToMove = Math.round(diffMinutes * easedT);

                // create a new date for the current step
                const newDate = moment(endDate).subtract(diffMinutes - minutesToMove, 'minutes');

                // set the new date
                this.setInitDateExact(newDate);
            } else {
                // end the interval if we've reached or passed the target date
                clearInterval(intervalId);
                // Make sure to set final date in case it didn't precisely hit the endDate
                this.setInitDate(endDate);

                this.setState({ isScrolling: false });

                if (afterScroll) afterScroll();
            }
        }, 0);
    };

    getWrapperClasses() {
        let classes = ['gantt-ui-wrapper'];
        if (this.state.isScrolling && !this.animate) classes.push('locked');
        return classes.join(' ');
    }

    render() {
        return (
            <div className={this.getWrapperClasses()} onMouseMove={this.handleMouseMove}>
                <InformationWindow visible={this.state.infoWindowVisible} content={this.state.infoWindowContent} x={this.state.mousePosition.x} y={this.state.mousePosition.y} />
                <ActionBar data={this.ganttData} functions={{ refreshGanttData: this.refreshGanttData, startAnimation: this.startAnimation, pauseAnimation: this.pauseAnimation, stopAnimation: this.stopAnimation }} isPaused={this.state.animPause} isStarted={this.animationInterval !== undefined} />
                <div className='gantt-root'>
                    <GanttTree
                        data={this.ganttData}
                        startDate={this.calcStartDate()}
                        endDate={this.calcEndDate()}
                        columns={[
                            { name: 'Bezeichnung', id: 'label' },
                            { name: 'Start', id: 'start', format: 'DD.MM.YYYY HH:mm' },
                            { name: 'Ende', id: 'end', format: 'DD.MM.YYYY HH:mm' },
                        ]}
                        functions={{ refreshGanttData: this.refreshGanttData, updateOptions: this.updateOptions }}
                        options={this.state.options}
                        vertScroll={this.state.vertScroll}
                        maxRows={this.state.maxRows}
                        focusedRow={this.state.focusedRow}
                    />
                    <GanttResizer ref={this.resizerRef} onResize={this.onResize} />
                    <GanttView
                        ref={this.viewRef}
                        data={this.ganttData}
                        startDate={this.calcStartDate()}
                        endDate={this.calcEndDate()}
                        viewStartDate={this.calcViewStartDate()}
                        viewEndDate={this.calcViewEndDate()}
                        initDate={this.state.initDate}
                        functions={{ refreshGanttData: this.refreshGanttData, setInitDate: this.setInitDate, incInitDate: this.incInitDate, updateOptions: this.updateOptions, setVertScroll: this.setVertScroll, doVertScroll: this.doVertScroll, setInfoWindowVisibility: this.setInfoWindowVisibility, setInfoWindowContent: this.setInfoWindowContent, scrollToDate: this.scrollToDate }}
                        numDays={this.state.numDays}
                        options={this.state.options}
                        ganttOptions={this.ganttOptions}
                        vertScroll={this.state.vertScroll}
                        maxRows={this.state.maxRows}
                        focusedRow={this.state.focusedRow}
                        focusStart={this.state.focusStart}
                    />
                    <div className={'progress-indicator'}>
                        <div ref={this.blobRef} className={'progress-indicator-blob'}></div>
                    </div>
                </div>
            </div>
        );
    }
}
