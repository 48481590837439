import React, { Component } from "react";
import "../../css/ripple.css";

export default class Ripple extends Component {
    mouseOver = false;
    animTimeout = undefined;

    mouseEnter = (e) => {
        this.mouseOver = true;
        this.resetAnimation(e);
    };

    mouseLeave = () => {
        this.mouseOver = false;
        clearInterval(this.animTimeout);
    };

    resetAnimation = (e) => {
        e.target.classList.add("stop");
        if (this.animTimeout !== undefined) clearInterval(this.animTimeout);
        this.animTimeout = setTimeout(() => {
            if (this.mouseOver) e.target.classList.remove("stop");
        }, 2000);
    };

    render() {
        const { className, children, ...restProps } = this.props;
        return (
            <div className={"ripple-parent " + className} {...restProps} onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave}>
                <div className="ripple"></div>
                {children}
            </div>
        );
    }
}
