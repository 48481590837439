import React, { Component } from 'react';
import GanttViewHead from './gantt-view-head';
import GanttViewContent from './gantt-view-content';
import GanttViewScrollOverlay from './gantt-view-scroll-overlay';
import GanttViewJumpBtns from './gantt-view-jump-btns';
import moment from 'moment';

export default class GanttView extends Component {
    isTouch = false;

    constructor() {
        super();

        this.viewContentRef = React.createRef();
        //this.isTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;
        this.isTouch = true;
    }

    render() {
        const diff = moment(this.props.initDate).startOf('day').diff(this.props.initDate, 'minutes');
        const delta = this.props.options.dayWidth * (diff / 1440);

        return (
            <div className='gantt-view'>
                <GanttViewJumpBtns
                    data={this.props.data}
                    options={this.props.options}
                    startDate={this.props.viewStartDate}
                    endDate={this.props.viewEndDate}
                    vertScroll={this.props.vertScroll}
                    maxRows={this.props.maxRows}
                    functions={{ setInitDate: this.props.functions.setInitDate, scrollToDate: this.props.functions.scrollToDate }}
                />
                {this.isTouch ? <GanttViewScrollOverlay functions={{ setInitDate: this.props.functions.setInitDate, incInitDate: this.props.functions.incInitDate }} /> : <></>}
                <div className='gantt-view-content-wrapper'>
                    <GanttViewHead
                        startDate={this.props.startDate}
                        endDate={this.props.endDate}
                        initDate={this.props.initDate}
                        options={this.props.options}
                        offset={delta}
                    />
                    <GanttViewContent
                        ref={this.viewContentRef}
                        startDate={this.props.startDate}
                        endDate={this.props.endDate}
                        initDate={this.props.initDate}
                        numDays={this.props.numDays}
                        data={this.props.data}
                        functions={{ refreshGanttData: this.props.functions.refreshGanttData, setInitDate: this.props.functions.setInitDate, incInitDate: this.props.functions.incInitDate, updateOptions: this.props.functions.updateOptions, setVertScroll: this.props.functions.setVertScroll, doVertScroll: this.props.functions.doVertScroll, setInfoWindowContent: this.props.functions.setInfoWindowContent, setInfoWindowVisibility: this.props.functions.setInfoWindowVisibility }}
                        options={this.props.options}
                        ganttOptions={this.props.ganttOptions}
                        vertScroll={this.props.vertScroll}
                        maxRows={this.props.maxRows}
                        focusedRow={this.props.focusedRow}
                        focusStart={this.props.focusStart}
                        offset={delta}
                    />
                </div>
            </div>
        );
    }
}
