import React, { Component } from 'react';

export default class GanttBar extends Component {
    infoWindowTimer;

    constructor() {
        super();

        this.infoRef = React.createRef();
    }

    handleMouseEnter = () => {
        if (this.props.ghost === 0) {
            this.infoWindowTimer = setTimeout(() => {
                this.props.functions.setInfoWindowContent(this.props.infoContent);
                this.props.functions.setInfoWindowVisibility(true);
            }, 500);
        }
    };

    handleMouseLeave = () => {
        if (this.props.ghost === 0) {
            clearInterval(this.infoWindowTimer);
            this.props.functions.setInfoWindowVisibility(false);
        }
    };

    infoWindow = () => {
        if (this.props.infoVisible) {
            let displayClasses = ['gantt-info-display'];
            if (this.props.infoStart === false) {
                displayClasses.push('end');
            }
            if (this.props.infoTop) {
                displayClasses.push('info-top');
            }
            return (
                <div className={'gantt-info-static-display-wrapper visible'}>
                    <div
                        className={'gantt-info-static-content-wrapper'}
                        ref={this.infoRef}
                    >
                        <div className={displayClasses.join(' ')}>{this.props.infoContent}</div>
                    </div>
                </div>
            );
        }
    };

    render() {
        const { children, className, style, onMouseDown, onClick } = this.props;
        return (
            <div
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                onMouseDown={onMouseDown}
                onClick={onClick}
                className={className}
                style={style}
            >
                {children}
                {this.infoWindow()}
            </div>
        );
    }
}
