import React, { Component } from 'react';
import moment from 'moment';
import * as Gantt from '../scripts/GanttChartData.js';
import Taskbar from './bars/taskbar.jsx';
import SummaryBar from './bars/summarybar.jsx';
import DateLine from './date-line.jsx';

export default class GanttViewContent extends Component {
    state = { selectedBars: [], vertScroll: 0, maxRows: 0 };
    temporaryBarData;
    trackedBarId;
    barOffset;
    mouseX;
    mouseY;
    moveDir = 0;
    refreshGhostbar = false;
    checkScrollInterval;
    toResolve = [];
    prepareStartMove;

    constructor() {
        super();

        this.contentRef = React.createRef();
        this.connectionsRef = React.createRef();

        this.checkScrollInterval = setInterval(() => {
            if (this.moveDir !== 0) {
                this.refreshGhostbar = true;
                this.props.functions.incInitDate(this.moveDir);
            }
        }, 100);

        window.addEventListener('mousedown', this.onMouseDown);
        window.addEventListener('mousemove', this.onMouseMove);
        window.addEventListener('mouseup', this.onMouseUp);
        window.addEventListener('mousewheel', this.onMouseWheel, { passive: false });
        window.addEventListener('keydown', this.onKeyDown);
    }

    componentWillUnmount() {
        clearInterval(this.checkScrollInterval);
        window.removeEventListener('mousedown', this.onMouseDown);
        window.removeEventListener('mousemove', this.onMouseMove);
        window.removeEventListener('mouseup', this.onMouseUp);
        window.removeEventListener('mousewheel', this.onMouseWheel);
        window.removeEventListener('keydown', this.onKeyDown);
    }

    onMouseWheel = (e) => {
        if (e.ctrlKey === true) {
            e.preventDefault();
            e.stopPropagation();

            let wheelDir = e.deltaY * -0.1;

            const dayWidth = this.props.options.dayWidth;
            if (dayWidth + wheelDir > 0) {
                this.props.functions.updateOptions({ dayWidth: dayWidth + wheelDir });
            }
        }
        if (e.shiftKey === true) {
            if (e.deltaY > 0) {
                this.props.functions.incInitDate(1 * 0.5);
            } else if (e.deltaY < 0) {
                this.props.functions.incInitDate(-1 * 0.5);
            }
        }

        if (e.ctrlKey !== true && e.shiftKey !== true) {
            this.props.functions.doVertScroll(Math.sign(e.deltaY));

            if (e.deltaX !== 0) {
                this.props.functions.incInitDate(Math.sign(e.deltaX) * 0.25);
            }
        }
    };

    onMouseMove = (e) => {
        if (this.prepareStartMove !== undefined) {
            let ev = this.prepareStartMove.event;
            let bar = this.prepareStartMove.bar;
            this.prepareStartMove = undefined;

            let mouseX = this.localPosition(ev.clientX).x;
            this.barOffset = this.dateToPosition(bar.start) - mouseX;
            this.temporaryBarData = this.props.data.simpleCopyRows();
            this.trackedBarId = bar.id;

            let ignoreSelection = false;
            if (this.state.selectedBars.length > 0) {
                if (this.state.selectedBars.find((b) => b.id === this.trackedBarId) === undefined) {
                    ignoreSelection = true;
                    this.setState({ selectedBars: [] });
                }
            }

            this.toResolve = [];
            if (!ignoreSelection && this.state.selectedBars.length > 0) {
                for (let i = 0; i < this.state.selectedBars.length; i++) {
                    this.toResolve.push(this.findBarById(this.temporaryBarData, this.state.selectedBars[i].id));
                }
            }

            let trackedBar = this.findBarById(this.temporaryBarData, this.trackedBarId);
            if (trackedBar.type === 'summary') {
                this.toResolve = this.toResolve.concat(this.recursiveDissolve(trackedBar.row));
            } else {
                if (ignoreSelection || this.state.selectedBars.find((b) => b.id === this.trackedBarId) === undefined) {
                    this.toResolve.push(this.findBarById(this.temporaryBarData, bar.id));
                }
            }
        }

        this.mouseX = e.clientX;
        this.mouseY = e.clientY;
        if (this.temporaryBarData !== undefined) {
            e.preventDefault();
            e.stopPropagation();
            this.updateGhostBar();
            // this.resolveConnections(this.toResolve);
            Gantt.Data.resolveConnections(this.toResolve, [], this.trackedBarId, this.state.selectedBars);
        }
    };

    recursiveDissolve(row) {
        let result = [];
        for (let i = 0; i < row.childRows.length; i++) {
            let childRow = row.childRows[i];
            for (let j = 0; j < childRow.bars.length; j++) {
                if (childRow.bars[j].type === 'task') result.push(childRow.bars[j]);
            }

            if (childRow.childRows.length > 0) result = result.concat(this.recursiveDissolve(childRow));
        }
        return result;
    }

    findBarById(rowData, barId) {
        let foundBar = null;

        function searchRow(row) {
            if (row.bars) {
                for (const bar of row.bars) {
                    if (bar.id === barId) {
                        foundBar = bar;
                        return;
                    }
                }
            }
            if (row.childRows && !foundBar) {
                for (const childRow of row.childRows) {
                    searchRow(childRow);
                    if (foundBar) return;
                }
            }
        }

        rowData.forEach((row) => {
            searchRow(row);
        });
        return foundBar;
    }

    updateGhostBar = () => {
        if (this.temporaryBarData !== undefined) {
            let trackedBar = this.findBarById(this.temporaryBarData, this.trackedBarId);
            let date = this.positionToDate(this.mouseX + this.barOffset);
            if (this.state.selectedBars.length > 0) {
                const deltaTime = date.diff(this.props.data.getBar(this.trackedBarId).start, 'minutes');
                for (let i = 0; i < this.state.selectedBars.length; i++) {
                    let origDate = moment(this.props.data.getBar(this.state.selectedBars[i].id).start);
                    this.findBarById(this.temporaryBarData, this.state.selectedBars[i].id).moveStart(origDate.add(deltaTime, 'minutes'), true);
                }
            } else if (trackedBar.type === 'summary') {
                const deltaTime = date.diff(this.props.data.getBar(this.trackedBarId).start, 'minutes');
                let childBars = this.recursiveDissolve(trackedBar.row);
                for (let i = 0; i < childBars.length; i++) {
                    let origDate = moment(this.props.data.getBar(childBars[i].id).start);
                    let newStart = origDate.add(deltaTime, 'minutes');
                    let tempBar = this.findBarById(this.temporaryBarData, childBars[i].id);
                    tempBar.moveStart(newStart, true);
                }
            } else {
                trackedBar.moveStart(date, true);
            }

            let fromLeft = this.localPosition(this.mouseX).x;
            this.moveDir = 0;
            if (fromLeft < 20) {
                this.moveDir = -1;
            } else {
                let fromRight = this.contentRef.current.getBoundingClientRect().width - fromLeft;
                if (fromRight < 20) {
                    this.moveDir = 1;
                }
            }

            this.forceUpdate();
        }
    };

    componentDidUpdate() {
        this.afterUpdate();
    }

    afterUpdate() {
        if (this.refreshGhostbar === true) {
            this.updateGhostBar();
            this.refreshGhostbar = false;
        }
    }

    onMouseUp = () => {
        this.endMove();
    };

    onKeyDown = (e) => {
        if (e.key === 'Escape' && this.temporaryBarData !== undefined) this.cancelMove();
    };

    startMove(e, bar) {
        if (this.props.ganttOptions.dragBars === true) {
            this.prepareStartMove = { event: e, bar: bar };
        }
    }

    endMove() {
        if (this.temporaryBarData !== undefined) {
            this.prepareStartMove = undefined;
            this.moveDir = 0;
            for (let i = 0; i < this.temporaryBarData.length; i++) {
                for (let j = 0; j < this.temporaryBarData[i].bars.length; j++) {
                    if (this.temporaryBarData[i].bars[j].hasMoved === true) {
                        this.props.data.applyChanges(this.temporaryBarData[i].bars[j]);
                    }
                }
            }
            this.temporaryBarData = undefined;
            this.props.functions.refreshGanttData();
            this.props.data.pushHistory();
        }
    }

    cancelMove() {
        this.temporaryBarData = undefined;
        this.props.functions.refreshGanttData();
    }

    onMouseDown = (e) => {
        if (e.target.classList.contains('gantt-bar')) {
            e.preventDefault();
            e.stopPropagation();
        } else if (!e.ctrlKey) {
            this.setState({ selectedBars: [] });
        }
    };

    onBarClick = (e, bar) => {
        this.prepareStartMove = undefined;
        if (e.ctrlKey) {
            if (this.state.selectedBars.includes(bar)) {
                let idx = this.state.selectedBars.indexOf(bar);
                let newSelectedBars = this.state.selectedBars;
                newSelectedBars.splice(idx, 1);
                this.setState({ selectedBars: newSelectedBars });
            } else {
                this.setState((state) => ({ selectedBars: state.selectedBars.concat([bar]) }));
            }
        } else {
            this.setState({ selectedBars: [bar] });
        }
    };

    getBackground() {
        let lines = [];
        let count = this.props.numDays;
        if (this.props.options.dayWidth > 450) {
            count = (count) * 24;
        }
        for (let i = 0; i < count; i++) {
            let style = { width: this.props.options.dayWidth > 450 ? this.props.options.dayWidth / 24 : this.props.options.dayWidth };
            if (this.props.options.dayWidth > 450 && i % 24 === 23) {
                style.borderColor = 'var(--primary)';
                style.opacity = 0.4;
            }

            let line = (
                <div
                    key={i}
                    className='gantt-view-content-background-line'
                    style={style}
                ></div>
            );
            lines.push(line);
        }
        return lines;
    }

    makeKey(bar, ghost) {
        let result = bar.type.substring(0, 1) + bar.id;
        if (ghost) result = 'g' + result;
        return result;
    }

    getBars = (rowData, belowCenter) => {
        let bars = [];
        for (let i = 0; i < rowData.bars.length; i++) {
            const createTaskbar = (bar, asGhost) => {
                if (bar.end.isSameOrAfter(this.props.startDate, 'minutes') && bar.start.isBefore(this.props.endDate, 'minutes')) {
                    let taskbar = (
                        <Taskbar
                            key={this.makeKey(bar, asGhost)}
                            ref={React.createRef()}
                            data={bar}
                            ghost={asGhost ? 1 : 0}
                            selected={this.state.selectedBars.includes(bar) ? 1 : 0}
                            functions={{ dateToPosition: this.dateToPosition, setInfoWindowContent: this.props.functions.setInfoWindowContent, setInfoWindowVisibility: this.props.functions.setInfoWindowVisibility }}
                            onMouseDown={(e) => this.startMove(e, bar)}
                            onClick={(e) => this.onBarClick(e, bar)}
                            infoVisible={rowData.id === this.props.focusedRow}
                            infoStart={this.props.focusStart}
                            infoTop={belowCenter}
                        />
                    );
                    bar.row = rowData;
                    bars.push(taskbar);
                }
            };

            if (rowData.bars[i].type === 'task') {
                createTaskbar(rowData.bars[i], false);

                if (this.temporaryBarData !== undefined) {
                    let ghostBar = this.findBarById(this.temporaryBarData, rowData.bars[i].id);
                    if (ghostBar !== undefined) createTaskbar(ghostBar, true);
                }
            }
        }

        const createSummaryBar = (bar, asGhost) => {
            if (!bar || !bar.start || !bar.end) return;

            if (bar.end.isSameOrAfter(this.props.startDate, 'days') && bar.start.isBefore(this.props.endDate, 'days')) {
                let summaryBar = (
                    <SummaryBar
                        key={this.makeKey(bar, asGhost)}
                        ref={React.createRef()}
                        data={bar}
                        ghost={asGhost ? 1 : 0}
                        selected={this.state.selectedBars.includes(bar) ? 1 : 0}
                        functions={{ dateToPosition: this.dateToPosition, setInfoWindowContent: this.props.functions.setInfoWindowContent, setInfoWindowVisibility: this.props.functions.setInfoWindowVisibility }}
                        onMouseDown={(e) => this.startMove(e, bar)}
                    />
                );
                bar.row = rowData;
                bars.push(summaryBar);
            }
        };

        if (rowData.summaryBar !== undefined) {
            let elData = this.findEarliestAndLatestDates(rowData);
            rowData.summaryBar.start = elData.earliestDate;
            rowData.summaryBar.end = elData.latestDate;
            createSummaryBar(rowData.summaryBar, false);

            if (this.temporaryBarData !== undefined) {
                let ghostRow = this.temporaryBarData.find((row) => row.id === rowData.id);
                let ghostElData = this.findEarliestAndLatestDates(ghostRow);
                ghostRow.summaryBar.start = ghostElData.earliestDate;
                ghostRow.summaryBar.end = ghostElData.latestDate;
                createSummaryBar(ghostRow.summaryBar, true);
            }
        }

        return bars;
    };

    findEarliestAndLatestDates(rowData) {
        let earliestDate = null;
        let latestDate = null;

        function updateDates(start, end) {
            if (!earliestDate || start < earliestDate) {
                earliestDate = start;
            }
            if (!latestDate || end > latestDate) {
                latestDate = end;
            }
        }

        function processRow(row) {
            if (row.bars) {
                row.bars.forEach((bar) => {
                    if (bar.type !== 'summary') updateDates(bar.start, bar.end);
                });
            }
            if (row.childRows) {
                row.childRows.forEach((childRow) => {
                    processRow(childRow);
                });
            }
        }

        processRow(rowData);

        return {
            earliestDate: earliestDate,
            latestDate: latestDate,
        };
    }

    getRows() {
        let rows = [];
        let visibleRows = this.props.data.getVisibleRows(this.props.startDate, this.props.endDate);

        let low = this.props.vertScroll;
        let high = Math.min(visibleRows.length, this.props.vertScroll + this.props.maxRows);
        for (let i = low; i < high; i++) {
            let exceptions = [];
            let key = 0;
            for (let j = 0; j < visibleRows[i].calendar.exceptions.length; j++) {
                const exception = visibleRows[i].calendar.exceptions[j];
                let startDate = moment(this.props.startDate);

                let firstAdded = false;

                if (exception.end.isSameOrAfter(this.props.startDate, 'minutes') && exception.start.isBefore(this.props.endDate, 'minutes')) {
                    let startPos = this.dateToPosition(exception.start);
                    let endPos = this.dateToPosition(exception.end);

                    let exceptionStyle = { left: startPos + 'px', width: endPos - startPos + 'px' };
                    let exceptionObj = (
                        <div
                            style={exceptionStyle}
                            key={key++}
                            className='gantt-exception'
                        ></div>
                    );
                    exceptions.push(exceptionObj);

                    firstAdded = true;
                }

                if (exception.recurring && exception.start.isBefore(this.props.endDate)) {
                    const endDate = exception.recurringEnd !== undefined ? moment.min(this.props.endDate, exception.recurringEnd) : this.props.endDate;

                    let currentExceptionStart = moment(exception.start);
                    let currentExceptionEnd = moment(exception.end);

                    while (currentExceptionEnd.isBefore(startDate)) {
                        firstAdded = false;
                        switch (exception.type) {
                            case Gantt.ExceptionType.DAILY:
                                currentExceptionStart.add(1, 'days');
                                currentExceptionEnd.add(1, 'days');
                                break;
                            case Gantt.ExceptionType.WEEKLY:
                                currentExceptionStart.add(1, 'weeks');
                                currentExceptionEnd.add(1, 'weeks');
                                break;
                            case Gantt.ExceptionType.MONTHLY:
                                currentExceptionStart.add(1, 'months');
                                currentExceptionEnd.add(1, 'months');
                                break;
                            case Gantt.ExceptionType.YEARLY:
                                currentExceptionStart.add(1, 'years');
                                currentExceptionEnd.add(1, 'years');
                                break;
                            default: // Shouldn't come here anytime
                                currentExceptionStart = moment(this.props.endDate).add(1, 'days');
                                currentExceptionEnd = moment(this.props.endDate).add(1, 'days');
                                break;
                        }
                    }

                    while (currentExceptionStart.isSameOrBefore(endDate)) {
                        if (!firstAdded) {
                            let startPos = this.dateToPosition(currentExceptionStart);
                            let endPos = this.dateToPosition(currentExceptionEnd);
                            let exceptionStyle = { left: startPos + 'px', width: endPos - startPos + 'px' };
                            let exceptionObj = (
                                <div
                                    style={exceptionStyle}
                                    key={key++}
                                    className='gantt-exception'
                                ></div>
                            );
                            exceptions.push(exceptionObj);
                        }
                        firstAdded = false;
                        switch (exception.type) {
                            case Gantt.ExceptionType.DAILY:
                                currentExceptionStart.add(1, 'days');
                                currentExceptionEnd.add(1, 'days');
                                break;
                            case Gantt.ExceptionType.WEEKLY:
                                currentExceptionStart.add(1, 'weeks');
                                currentExceptionEnd.add(1, 'weeks');
                                break;
                            case Gantt.ExceptionType.MONTHLY:
                                currentExceptionStart.add(1, 'months');
                                currentExceptionEnd.add(1, 'months');
                                break;
                            case Gantt.ExceptionType.YEARLY:
                                currentExceptionStart.add(1, 'years');
                                currentExceptionEnd.add(1, 'years');
                                break;
                            default: // Shouldn't come here anytime
                                currentExceptionStart = moment(this.props.endDate.add(1, 'days'));
                                break;
                        }
                    }
                }
            }

            let bars = this.getBars(visibleRows[i], i > 7 && i > visibleRows.length - 10);

            let classes = ['gantt-view-row', 'row'];
            if (this.props.focusedRow === visibleRows[i].id) {
                classes.push('focused');
            }

            let row = (
                <div
                    ref={React.createRef()}
                    key={i}
                    className={classes.join(' ')}
                    style={{ minHeight: this.props.options.rowHeight, maxHeight: this.props.options.rowHeight }}
                >
                    {exceptions}
                    {bars}
                </div>
            );
            rows.push(row);
        }
        return rows;
    }

    calculateConnections() {
        const lineWidth = 1;

        let lines = [];
        let connections = [];

        for (let i = 0; i < this.props.data.rows.length; i++) {
            for (let j = 0; j < this.props.data.rows[i].bars.length; j++) {
                for (let k = 0; k < this.props.data.rows[i].bars[j].connections.length; k++) {
                    const fromBar = this.props.data.rows[i].bars[j].connections[k].fromBar;
                    const toBar = this.props.data.rows[i].bars[j].connections[k].toBar;
                    if (fromBar.isVisible() || toBar.isVisible()) connections.push(this.props.data.rows[i].bars[j].connections[k]);
                }
            }
        }

        if (this.temporaryBarData !== undefined) {
            for (let i = 0; i < this.temporaryBarData.length; i++) {
                for (let k = 0; k < this.temporaryBarData[i].bars.length; k++) {
                    for (let j = 0; j < this.temporaryBarData[i].bars[k].connections.length; j++) {
                        const fromBar = this.temporaryBarData[i].bars[k].connections[k].fromBar;
                        const toBar = this.temporaryBarData[i].bars[k].connections[k].toBar;
                        let ghostConnection = Object.assign({}, this.temporaryBarData[i].bars[k].connections[k]);
                        ghostConnection.ghost = true;
                        if (fromBar.isVisible() || toBar.isVisible()) connections.push(ghostConnection);
                    }
                }
            }
        }

        for (let l = 0; l < connections.length; l++) {
            let fromBar = connections[l].fromBar;
            let toBar = connections[l].toBar;

            if (fromBar.end > this.props.endDate && toBar.start > this.props.endDate) continue;
            if (fromBar.end < this.props.startDate && toBar.start < this.props.startDate) continue;

            let fromBarRect = this.calculateBarRect(fromBar);
            let toBarRect = this.calculateBarRect(toBar);

            let lineStartPos = { x: fromBarRect.x + fromBarRect.width, y: fromBarRect.y + fromBarRect.height / 2 };
            let lineEndPos = { x: toBarRect.x, y: toBarRect.y + toBarRect.height / 2 };

            let line = [];
            let lineCoords = this.calculateLine(lineStartPos, lineEndPos);
            for (let i = 0; i < lineCoords.length - 1; i++) {
                let left = lineCoords[i].x;
                let top = lineCoords[i].y;
                let width = lineCoords[i + 1].x - lineCoords[i].x;
                let height = lineCoords[i + 1].y - lineCoords[i].y;
                if (width === 0) width = lineWidth;
                if (height === 0) height = lineWidth;
                if (width < 0) {
                    left += width;
                    width *= -1;
                    width += lineWidth;
                }
                if (height < 0) {
                    top += height;
                    height *= -1;
                    height += lineWidth;
                }

                let classes = ['gantt-line-segment'];

                let wrapperWidth = 0;
                let wrapperHeight = 0;

                const lineExtent = 8;

                if (width > height) {
                    classes.push('hline');
                    wrapperHeight = lineExtent;
                    wrapperWidth = wrapperHeight / 2;
                } else {
                    classes.push('vline');
                    wrapperWidth = lineExtent;
                    wrapperHeight = wrapperWidth / 2;
                }

                if (lineCoords[0].x === lineCoords[lineCoords.length - 1].x) {
                    classes.push('sameX');
                }

                let style = { left: left - wrapperWidth / 2 + 'px', top: top - wrapperHeight / 2 + 'px', width: width + wrapperWidth + 'px', height: height + wrapperHeight + 'px' };
                let lineStyle = { width: width + 'px', height: height + 'px' };
                if (connections[l].ghost === true) classes.push('ghost');
                let lineSegment = (
                    <div
                        style={lineStyle}
                        className={classes.join(' ')}
                    ></div>
                );
                let lineWrapper = (
                    <div
                        key={i}
                        style={style}
                        className='gantt-line-segment-wrapper'
                    >
                        {lineSegment}
                    </div>
                );

                line.push(lineWrapper);
            }

            let pufferLabel = <></>;
            if (connections[l].puffer.asMilliseconds() !== 0) {
                let pufferStr = '';

                if (connections[l].puffer.asDays() >= 1) {
                    pufferStr = connections[l].puffer.asDays().toLocaleString() + 'd';
                } else if (connections[l].puffer.asHours() >= 1) {
                    pufferStr = connections[l].puffer.asHours().toLocaleString() + 'h';
                } else if (connections[l].puffer.asMinutes() >= 1) {
                    pufferStr = connections[l].puffer.asMinutes().toLocaleString() + 'm';
                } else if (connections[l].puffer.asSeconds() >= 1) {
                    pufferStr = connections[l].puffer.asSeconds().toLocaleString() + 's';
                } else if (connections[l].puffer.asMilliseconds() >= 1) {
                    pufferStr = connections[l].puffer.asMilliseconds().toLocaleString() + 'ms';
                } else {
                    pufferStr = '?';
                }

                if (connections[l].puffer.asMilliseconds() > 0) pufferStr = '+' + pufferStr;
                let style = { left: lineCoords[Math.floor(lineCoords.length / 2) - 1].x + 'px', top: lineCoords[Math.floor(lineCoords.length / 2) - 1].y + 'px' };
                pufferLabel = (
                    <div
                        className='gantt-line-puffer-label'
                        style={style}
                    >
                        {pufferStr}
                    </div>
                );
            }

            let lineClasses = ['gantt-line'];
            if (connections[l].ghost === true) lineClasses.push('ghost');
            lines.push(
                <div
                    key={l}
                    ref={React.createRef()}
                    onMouseEnter={this.lineMouseEnter}
                    onMouseLeave={this.lineMouseLeave}
                    style={{ top: -lineWidth / 2 + 'px' }}
                    className={lineClasses.join(' ')}
                >
                    {pufferLabel}
                    {line}
                </div>
            );
        }
        return lines;
    }

    lineMouseEnter(e) {
        e.target.parentNode.classList.add('mouseover');
    }

    lineMouseLeave(e) {
        e.target.parentNode.classList.remove('mouseover');
    }

    calculateBarRect(bar) {
        const computedStyle = getComputedStyle(document.documentElement);
        const rowHeight = this.props.options.rowHeight;
        const barHeight = rowHeight * (parseInt(computedStyle.getPropertyValue('--bar-height')) / 100);

        let x = this.dateToPosition(bar.start);
        let width = this.dateToPosition(bar.end) - x;
        let visibleRows = this.props.data.getVisibleRows(this.props.startDate, this.props.endDate);
        let rowIndex = visibleRows.indexOf(bar.row);
        let offset = false;
        if (rowIndex === -1) {
            offset = true;
            let parent = bar.row.parent;
            while (parent !== undefined && visibleRows.indexOf(parent) === -1) {
                parent = parent.parent;
            }
            rowIndex = visibleRows.indexOf(parent);
        }
        let y = rowIndex === -1 ? 0 : (rowIndex - this.props.vertScroll) * rowHeight + (rowHeight - barHeight) / 2;
        if (offset === true) y += rowHeight / 2;
        let height = rowIndex === -1 ? 0 : barHeight;

        return { x: x, y: y, width: width, height: height };
    }

    calculateLine(start, end) {
        const rowHeight = this.props.options.rowHeight;
        const epsilon = 5;
        if (start.y === end.y) {
            if (start.x < end.x) {
                // 1 line - 2 points
                return [
                    { x: start.x, y: start.y },
                    { x: end.x, y: end.y },
                ];
            } else {
                // 5 lines - 6 points
                return [
                    { x: start.x, y: start.y },
                    { x: start.x + epsilon, y: start.y },
                    { x: start.x + epsilon, y: start.y + rowHeight / 2 },
                    { x: end.x - epsilon, y: start.y + rowHeight / 2 },
                    { x: end.x - epsilon, y: end.y },
                    { x: end.x, y: end.y },
                ];
            }
        } else if (start.x < end.x - epsilon) {
            // 3 lines - 4 points
            return [
                { x: start.x, y: start.y },
                { x: end.x - epsilon, y: start.y },
                { x: end.x - epsilon, y: end.y },
                { x: end.x, y: end.y },
            ];
        } else {
            // 5 lines - 6 points
            return [
                { x: start.x, y: start.y },
                { x: start.x + epsilon, y: start.y },
                { x: start.x + epsilon, y: start.y + rowHeight / 2 },
                { x: end.x - epsilon, y: start.y + rowHeight / 2 },
                { x: end.x - epsilon, y: end.y },
                { x: end.x, y: end.y },
            ];
        }
    }

    getDateLines() {
        const { dateLines } = this.props.data;

        let dls = [];
        for (let i = 0; i < dateLines.length; i++) {
            if (dateLines[i].getDate().isSameOrAfter(this.props.startDate, 'minutes') && dateLines[i].getDate().isBefore(this.props.endDate, 'minutes')) {
                let dateLine = (
                    <DateLine
                        key={i}
                        ref={React.createRef()}
                        data={dateLines[i]}
                        functions={{ dateToPosition: this.dateToPosition }}
                    />
                );
                dls.push(dateLine);
            }
        }
        return dls;
    }

    positionToDate(x) {
        const minuteOffset = this.props.initDate.diff(moment(this.props.initDate).startOf('day'), 'minutes');

        const dayWidth = this.props.options.dayWidth;
        let localPosition = this.localPosition(x);
        let deltaDays = localPosition.x / dayWidth;
        return moment(this.props.startDate).add(deltaDays * 24 * 60 + minuteOffset, 'minutes');
    }

    dateToPosition = (date) => {
        const minuteOffset = this.props.initDate.diff(moment(this.props.initDate).startOf('day'), 'minutes');

        const dayWidth = this.props.options.dayWidth;
        let deltaDays = (date.diff(this.props.startDate, 'minutes') + minuteOffset) / 60 / 24;
        return deltaDays * dayWidth;
    };

    localPosition(x, y) {
        let rect = this.contentRef.current.getBoundingClientRect();
        let localX;
        if (x !== undefined) localX = x - rect.left;
        let localY;
        if (y !== undefined) localY = y - rect.top;
        return { x: localX, y: localY };
    }

    render() {
        return (
            <div
                ref={this.contentRef}
                className='gantt-view-content'
                style={{ transform: `translateX(${this.props.offset}px)` }}
            >
                <div className='gantt-view-content-background'>{this.getBackground()}</div>
                <div ref={this.connectionsRef}>{this.getRows()}</div>
                <div className='gantt-date-lines'>{this.getDateLines()}</div>
                <div className='gantt-line-overlay'>{this.calculateConnections()}</div>
            </div>
        );
    }
}
