import React, { Component } from "react";
import moment from "moment";

export default class GanttViewHeadDay extends Component {
    constructor() {
        super();

        this.dayRef = React.createRef();
    }

    getClasses() {
        let classes = ["gantt-view-head-day"];
        if (this.props.date.isSame(moment(), "day")) {
            classes.push("current-date");
        }
        return classes.join(" ");
    }

    getStyle() {
        return { width: this.props.options.dayWidth };
    }

    render() {
        if (this.props.options.dayWidth <= 450) {
            return (
                <div
                    ref={this.dayRef}
                    className={this.getClasses()}
                    style={this.getStyle()}
                >
                    <div className='gantt-view-head-day-num'>{this.props.date.format("DD")}</div>
                    <div className='gantt-view-head-day-txt'>{this.props.date.format("dd")}</div>
                </div>
            );
        } else {
            return (
                <div
                    ref={this.dayRef}
                    className={this.getClasses()}
                    style={this.getStyle()}
                >
                    <div className='gantt-view-head-day-num'>{this.props.date.format("DD.MM.YYYY")}</div>
                    <div className='gantt-view-head-day-txt'>{this.props.date.format("dddd")}</div>
                </div>
            );
        }
    }
}
