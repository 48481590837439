import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';

export default class ActionBarSlider extends Component {
    state = { value: undefined };

    getValue() {
        return this.state.value;
    }

    componentDidMount() {
        if (this.props.value) {
            this.setState({ value: parseInt(this.props.value) });
        } else {
            this.setState({ value: this.props.defaultValue });
        }

        window.addEventListener('mouseup', this.onMouseUp);
    }

    componentWillUnmount() {
        window.removeEventListener('mouseup', this.onMouseUp);
    }

    getTicks() {
        let ticks = [];
        for (let i = 0; i < this.props.levels; i++) {
            let classes = ['tick'];
            if (this.state.value === i) {
                classes.push('selected');
            }

            ticks.push(
                <div
                    key={i}
                    className={classes.join(' ')}
                ></div>
            );
        }
        return ticks;
    }

    getIcons() {
        let icons = [];
        if (this.props.startIcon) {
            icons.push(
                <FontAwesomeIcon
                    key={icons.length}
                    className={'start-icon'}
                    icon={this.props.startIcon}
                />
            );
        }

        if (this.props.endIcon) {
            icons.push(
                <FontAwesomeIcon
                    key={icons.length}
                    className={'end-icon'}
                    icon={this.props.endIcon}
                />
            );
        }
        return icons;
    }

    mouseDown = false;

    onMouseDown = (e) => {
        this.mouseDown = true;
        e.preventDefault();
        e.stopPropagation();

        let rect = e.target.getBoundingClientRect();
        let x = e.clientX - (rect.left + 5);
        let level = Math.round((x / (e.target.clientWidth - 5)) * (this.props.levels - 1));
        if (level < 0) level = 0;
        if (level > this.props.levels - 1) level = this.props.levels - 1;
        this.setState({ value: level });
    };

    onMouseUp = (e) => {
        this.mouseDown = false;
    };

    onMouseMove = (e) => {
        if (this.mouseDown) {
            e.preventDefault();
            e.stopPropagation();

            let rect = e.target.getBoundingClientRect();
            let x = e.clientX - (rect.left + 5);
            let level = Math.round((x / (e.target.clientWidth - 5)) * (this.props.levels - 1));
            if (level < 0) level = 0;
            if (level > this.props.levels - 1) level = this.props.levels - 1;
            this.setState({ value: level });
        }
    };

    render() {
        return (
            <div
                className={'action-bar-slider-wrapper'}
                onMouseDown={this.onMouseDown}
                onMouseMove={this.onMouseMove}
            >
                <div className={'action-bar-slider-caption'}>{this.props.caption}</div>
                <div className={'action-bar-slider'}>
                    {this.getTicks()}
                    {this.getIcons()}
                </div>
            </div>
        );
    }
}
