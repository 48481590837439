import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAnglesLeft, faAngleRight, faAnglesRight, faArrowsLeftRight, faFaceGrinBeam } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

export default class GanttViewScrollOverlay extends Component {
    state = { scrolling: false, dir: 0 };
    attached = true;
    deltaX = 0;
    deltaXN = 0;
    scrollVal = 0;
    scrollThreshhold = 0.03;
    lastTime = 0;

    particleVal = 0;
    particleThreshhold = 0.03;
    particles = [];

    constructor() {
        super();

        this.overlayRef = React.createRef();
        this.prevRef = React.createRef();
        this.thumbRef = React.createRef();
        this.nextRef = React.createRef();
        this.canvasRef = React.createRef();
        this.backgroundRef = React.createRef();

        document.documentElement.addEventListener("mouseup", (e) => {
            if (this.state.scrolling) {
                this.thumbRef.current.classList.remove("dragging");
                this.backgroundRef.current.classList.remove("visible");
                this.thumbRef.current.style.left = "";
                this.thumbRef.current.style.scale = "";
                this.prevRef.current.style.opacity = "";
                this.nextRef.current.style.opacity = "";
                this.deltaX = 0;
                this.deltaXN = 0;
                this.scrollVal = 0;
                this.attached = true;
                this.setState({ scrolling: false, dir: 0 });
            }

            this.prevDown = false;
            if (this.prevInterval !== undefined) clearInterval(this.prevInterval);
            if (this.prevTimeout !== undefined) clearTimeout(this.prevTimeout);
            this.nextDown = false;
            if (this.nextInterval !== undefined) clearInterval(this.nextInterval);
            if (this.nextTimeout !== undefined) clearTimeout(this.nextTimeout);
        });

        document.documentElement.addEventListener("mousemove", (e) => {
            if (this.state.scrolling) {
                this.deltaX = e.clientX - this.startX;
                if (Math.abs(this.deltaX > 1)) this.attached = false;
                this.thumbRef.current.style.left = "calc(50% + " + this.deltaX + "px)";
                let computedStyle = getComputedStyle(this.thumbRef.current);
                let parentWidth = parseInt(getComputedStyle(this.overlayRef.current.parentNode).width);
                if (this.deltaX < -parentWidth / 2) this.deltaX = -parentWidth / 2;
                if (this.deltaX > parentWidth / 2) this.deltaX = parentWidth / 2;
                this.deltaXN = this.deltaX / (parentWidth / 2);
                if (parseInt(computedStyle.left) < parentWidth * 0.125) {
                    this.thumbRef.current.style.left = parentWidth * 0.125 + "px";
                } else if (parseInt(computedStyle.left) > parentWidth * 0.875) {
                    this.thumbRef.current.style.left = parentWidth * 0.875 + "px";
                }
                let maxVal = parentWidth / 2;
                let scale = 1;
                if (this.deltaX < 0) {
                    scale = 1 + (1 - parseInt(computedStyle.left) / maxVal) * 0.2;
                    this.thumbRef.current.classList.add("left");
                    this.thumbRef.current.classList.remove("right");
                } else {
                    scale = 1 + (1 - parseInt(computedStyle.right) / maxVal) * 0.2;
                    this.thumbRef.current.classList.remove("left");
                    this.thumbRef.current.classList.add("right");
                }
                if (Math.abs(this.deltaXN) > 0.75) {
                    this.setState({ dir: Math.sign(this.deltaX) * 2 });
                } else {
                    this.setState({ dir: Math.sign(this.deltaX) });
                }
                this.thumbRef.current.style.scale = scale;
            }
        });
    }

    componentDidMount() {
        const iconCallback = (dt) => {
            if (Math.abs(this.deltaXN) > 0.2) {
                this.thumbRef.current.classList.remove("nospeed");
                this.scrollVal += this.deltaXN * dt;
                if (Math.abs(this.scrollVal) > this.scrollThreshhold) {
                    const computedStyle = getComputedStyle(this.thumbRef.current);
                    const overlayComputedStyle = getComputedStyle(this.overlayRef.current);
                    let targetPos = { x: parseInt(overlayComputedStyle.width) / 2, y: parseInt(overlayComputedStyle.height) / 2 };
                    let x = parseInt(computedStyle.left);
                    let y = Math.random() * parseInt(computedStyle.height) + parseInt(computedStyle.top);

                    if (Math.abs(this.deltaXN) > 0.75) {
                        this.thumbRef.current.classList.add("highspeed");
                        this.props.functions.incInitDate(Math.sign(this.scrollVal) * 5);
                        this.particles.push(this.newParticle(x, y, overlayComputedStyle.getPropertyValue("--secondary"), targetPos));
                    } else {
                        this.thumbRef.current.classList.remove("highspeed");
                        this.props.functions.incInitDate(Math.sign(this.scrollVal));
                        this.particles.push(this.newParticle(x, y, overlayComputedStyle.getPropertyValue("--primary"), targetPos));
                    }

                    this.scrollVal = 0;
                }
            } else {
                this.thumbRef.current.classList.add("nospeed");
                this.thumbRef.current.classList.remove("highspeed");
            }
        };

        const particleCallback = (dt) => {
            this.updateParticles(dt);
        };

        let lastUpdate = Date.now();
        const intervalCallbacks = () => {
            const now = Date.now();
            const dt = (now - lastUpdate) / 1000;
            lastUpdate = now;
            iconCallback(dt);
            particleCallback(dt);
        };

        this.interval = setInterval(intervalCallbacks, 0);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        if (this.prevInterval !== undefined) clearInterval(this.prevInterval);
        if (this.nextInterval !== undefined) clearInterval(this.nextInterval);
        if (this.prevTimeout !== undefined) clearTimeout(this.prevTimeout);
        if (this.nextTimeout !== undefined) clearTimeout(this.nextTimeout);
    }

    onMouseDown = (e) => {
        this.attached = true;
        this.startX = e.clientX;
        this.setState({ scrolling: true });
        this.thumbRef.current.classList.add("dragging");
        this.prevRef.current.style.opacity = 0;
        this.nextRef.current.style.opacity = 0;
        this.backgroundRef.current.classList.add("visible");
    };

    getThumbIcon() {
        switch (this.state.dir) {
            case -2:
                return <FontAwesomeIcon icon={faAnglesLeft} />;
            case -1:
                return <FontAwesomeIcon icon={faAngleLeft} />;
            default:
                return this.attached ? <FontAwesomeIcon icon={faArrowsLeftRight} /> : <FontAwesomeIcon icon={faFaceGrinBeam} />;
            case 1:
                return <FontAwesomeIcon icon={faAngleRight} />;
            case 2:
                return <FontAwesomeIcon icon={faAnglesRight} />;
        }
    }

    lerp(a, b, t) {
        let res = a * (1 - t) + b * t;
        return res;
    }

    updateParticles(dt) {
        if (this.particles.length > 0) {
            const overlayComputedStyle = getComputedStyle(this.overlayRef.current);
            const canvas = this.canvasRef.current;
            canvas.width = parseInt(overlayComputedStyle.width);
            canvas.height = parseInt(overlayComputedStyle.height);
            const cw2 = canvas.width / 2;
            let ctx = canvas.getContext("2d");
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            for (let i = this.particles.length - 1; i >= 0; i--) {
                this.particles[i].lifeTime -= dt * 1000;
                if (this.particles[i].lifeTime <= 0) {
                    this.particles.splice(i, 1);
                } else {
                    this.particles[i].x = this.lerp(this.particles[i].x, this.particles[i].targetPos.x, (0.4 + this.particles[i].speed) * dt);
                    this.particles[i].y = this.lerp(this.particles[i].y, this.particles[i].targetPos.y, 0.4 * dt);

                    ctx.fillStyle = this.particles[i].color;
                    let alpha = Math.abs(this.particles[i].x - this.particles[i].targetPos.x) / (parseInt(overlayComputedStyle.width) / 2) - 0.1;
                    if (alpha < 0) alpha = 0;
                    ctx.globalAlpha = alpha * (this.particles[i].lifeTime / this.particles[i].maxLifeTime);
                    ctx.beginPath();
                    ctx.arc(this.particles[i].x, this.particles[i].y, 2, 0, 2 * Math.PI);
                    ctx.fill();

                    let closestParticles = this.findClosestParticles(i, 3);
                    ctx.globalAlpha = alpha * 0.1;
                    ctx.strokeStyle = this.particles[i].color;
                    for (let j = 0; j < closestParticles.length; j++) {
                        if ((closestParticles[j].x < cw2 && this.particles[i].x > cw2) || (closestParticles[j].x > cw2 && this.particles[i].x < cw2)) continue;
                        ctx.moveTo(this.particles[i].x, this.particles[i].y);
                        ctx.lineTo(closestParticles[j].x, closestParticles[j].y);
                        ctx.stroke();
                    }
                }
            }
        }
    }

    findClosestParticles(index, num) {
        let candidates = [];
        for (let i = 0; i < this.particles.length; i++) {
            if (i === index) continue;
            let pCopy = Object.assign({}, this.particles[i]);
            let dx = pCopy.x - this.particles[index].x;
            let dy = pCopy.y - this.particles[index].y;
            pCopy.dist = Math.sqrt(dx * dx + dy * dy);
            candidates.push(pCopy);
        }
        candidates.sort((a, b) => (a.dist > b.dist ? 1 : -1));
        return candidates.slice(0, 3);
    }

    newParticle(x, y, color, targetPos) {
        let particle = {
            x: x,
            y: y,
            maxLifeTime: 3000,
            lifeTime: 3000,
            color: color,
            speed: Math.random() * 0.5,
            targetPos: targetPos,
        };
        return particle;
    }

    onDoubleClick = () => {
        this.props.functions.setInitDate(moment());
    };

    onPrevDown = (e) => {
        const overlayComputedStyle = getComputedStyle(this.overlayRef.current);
        const prevComputedStyle = getComputedStyle(this.prevRef.current);
        const x = parseInt(prevComputedStyle.left);
        const y = parseInt(prevComputedStyle.top);
        const targetPos = { x: parseInt(overlayComputedStyle.width) / 2, y: parseInt(overlayComputedStyle.height) / 2 };

        this.props.functions.incInitDate(-1);
        this.particles.push(this.newParticle(x, y + Math.random() * parseInt(prevComputedStyle.height), overlayComputedStyle.getPropertyValue("--primary"), targetPos));
        this.prevDown = true;
        this.prevTimeout = setTimeout(() => {
            if (this.prevDown) {
                this.prevInterval = setInterval(() => {
                    this.props.functions.incInitDate(-1);
                    this.particles.push(this.newParticle(x, y + Math.random() * parseInt(prevComputedStyle.height), overlayComputedStyle.getPropertyValue("--primary"), targetPos));
                }, 80);
            }
        }, 400);
    };

    onNextDown = (e) => {
        const overlayComputedStyle = getComputedStyle(this.overlayRef.current);
        const nextComputedStyle = getComputedStyle(this.nextRef.current);
        const x = parseInt(nextComputedStyle.left) + parseInt(nextComputedStyle.width);
        const y = parseInt(nextComputedStyle.top);
        const targetPos = { x: parseInt(overlayComputedStyle.width) / 2, y: parseInt(overlayComputedStyle.height) / 2 };

        this.props.functions.incInitDate(1);
        this.particles.push(this.newParticle(x, y + Math.random() * parseInt(nextComputedStyle.height), overlayComputedStyle.getPropertyValue("--primary"), targetPos));
        this.nextDown = true;
        this.nextTimeout = setTimeout(() => {
            if (this.nextDown) {
                this.nextInterval = setInterval(() => {
                    this.props.functions.incInitDate(1);
                    this.particles.push(this.newParticle(x, y + Math.random() * parseInt(nextComputedStyle.height), overlayComputedStyle.getPropertyValue("--primary"), targetPos));
                }, 80);
            }
        }, 400);
    };

    render() {
        return (
            <div
                ref={this.overlayRef}
                className='gantt-scroll-overlay'
            >
                <div
                    ref={this.prevRef}
                    className='gantt-scroll-overlay-prev'
                    onMouseDown={this.onPrevDown}
                >
                    <FontAwesomeIcon icon={faAngleLeft} />
                </div>

                <div
                    ref={this.thumbRef}
                    className='gantt-scroll-overlay-thumb'
                    onMouseDown={this.onMouseDown}
                    onDoubleClick={this.onDoubleClick}
                >
                    {this.getThumbIcon()}
                </div>

                <div
                    ref={this.nextRef}
                    className='gantt-scroll-overlay-next'
                    onMouseDown={this.onNextDown}
                >
                    <FontAwesomeIcon icon={faAngleRight} />
                </div>

                <canvas
                    ref={this.canvasRef}
                    className='gantt-scroll-overlay-particle-canvas'
                ></canvas>

                <div
                    ref={this.backgroundRef}
                    className='gantt-scroll-overlay-background'
                >
                    <div className='gantt-scroll-overlay-background-strip lmax'></div>
                    <div className='gantt-scroll-overlay-background-strip l3'></div>
                    <div className='gantt-scroll-overlay-background-strip l2'></div>
                    <div className='gantt-scroll-overlay-background-strip l1'></div>
                    <div className='gantt-scroll-overlay-background-strip lmin'></div>
                    <div className='gantt-scroll-overlay-background-dot'></div>
                    <div className='gantt-scroll-overlay-background-strip rmin'></div>
                    <div className='gantt-scroll-overlay-background-strip r1'></div>
                    <div className='gantt-scroll-overlay-background-strip r2'></div>
                    <div className='gantt-scroll-overlay-background-strip r3'></div>
                    <div className='gantt-scroll-overlay-background-strip rmax'></div>
                </div>
            </div>
        );
    }
}
